import { useTrail, animated } from '@react-spring/web';
import {Menu} from "antd";
import {isNull} from "../utils/HtmlUtils";

export default function AnimatedMenu(props) {
    const {items} = props;
    const length = isNull(items, []).length;
    const trails = useTrail(length, {
        from: { opacity: 0, x: -20 },
        to: { opacity: 1, x: 0 },
        config: {duration: 400/length, friction: 50}
    });

    const animatedItems = trails.map((trail, index) => {
        const item = items[index]
        const over = {
            label: <animated.span style={{display: "inline-block", ...trail}}>{item.label}</animated.span>,
            icon: <animated.span style={{display: "inline-block", verticalAlign: 0, ...trail}}>{item.icon}</animated.span>,
        }
        return Object.assign({}, item, over);
    })

    return (
        <span className="animated-menu">
            <Menu {...props} items={animatedItems}/>
        </span>
    );
};