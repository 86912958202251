import DefaultModalButton from "../../../../components/default-modal-button";
import {deleteBot} from "../../../../api";

const formConfig = [
    {key: 'id'},
    {key: 'name'}
]

const modalRender = (state) => {
    return `警告，删除后无法恢复，确定删除${state.name}吗？`;
}

export default function DeleteButton(props) {
    return <DefaultModalButton
        {...props}
        value="删除"
        formConfig={formConfig}
        updateApi={deleteBot}
        disabled={props.selectedRows.length !== 1}
        modalRender={modalRender}
    />
}