import React, {useState} from "react";
import {Button, Card, Steps, Form, Row, Space, Col, Spin} from "antd";
import {If} from "../../utils/HtmlUtils";
import TextInput from "../../components/TextInput";
import {checkCode, checkEmail, checkEmailCode, register} from "../../api";
import Countdown from "../../components/Countdown";
import useJumper from "../../components/UseJumper";

const steps = ['邀请码','邮箱验证','注册账号'];
const items = steps.map((item) => ({ key: item, title: item }));

export default function Register() {
    const {jumpToLogin, jumpToSuccess} = useJumper();
    const [current, setCurrent] = useState(0);
    const [disable, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    const [code, setCode] = useState(null);
    const [email, setEmail] = useState(null);
    const [emailCode, setEmailCode] = useState(null);
    const [password, setPassword] = useState(null);
    const [master, setMaster] = useState(null);

    function next() {
        setCurrent(current + 1);
    }

    function prev() {
        setCurrent(current - 1);
    }

    function startLoad() {
        setLoading(false);
    }

    function endLoad() {
        setLoading(false);
    }

    function getRegisterData() {
        return {code, master, email, emailCode, password}
    }

    function reqCheckCode() {
        startLoad();
        checkCode(getRegisterData()).then(next).then(endLoad)
    }

    function reqCheckEmailCode() {
        startLoad();
        checkEmailCode(getRegisterData()).then(next).then(endLoad)
    }

    function reqCheckEmail() {
        startLoad();
        checkEmail(getRegisterData()).finally(() => {
            setDisable(true);
            setTimeout(() => {
                setDisable(false);
            }, 60000);
        }).then(endLoad)
    }

    function reqRegister() {
        startLoad();
        register(getRegisterData()).then(endLoad).then(() => {
            jumpToSuccess()
        })
    }

    return (
        <Row type="flex" align="middle" className="max-height">
            <Col flex="auto" >
                <Row justify="center">
                    <Spin spinning={loading}>
                        <Card style={{width: "500px"}}>
                            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                                <Steps current={current} items={items} />
                                <Form layout="horizontal">
                                    {If(current === 0).then(() => <>
                                        <Form.Item label="主人扣口好" labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
                                            <TextInput value={master} onChange={setMaster} type="master"/>
                                        </Form.Item>
                                        <Form.Item label="邀请码" labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
                                            <TextInput value={code} onChange={setCode} type="code" onPressEnter={reqCheckCode}/>
                                        </Form.Item>
                                        <Row justify="center">
                                            <span>PS: 现在还在内测中，人太多我BUG修不过来。</span>
                                            <span>所以请私聊我(545459363)</span>
                                        </Row>
                                        <Row type="flex" justify="space-around" align="middle">
                                            <Button onClick={jumpToLogin}>已有账号</Button>
                                            <Button type="primary" onClick={reqCheckCode}>确认邀请码</Button>
                                        </Row>
                                    </>).elseIf(current === 1).then(() => <>
                                        <Form.Item label="邮箱" labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
                                            <Space.Compact style={{ width: '100%' }}>
                                                <TextInput value={email} onChange={setEmail} type="email"/>
                                                <Button onClick={reqCheckEmail} disabled={disable}>
                                                    验证
                                                    {If(disable).then(() => (
                                                        <Countdown disable={disable} duration={60}/>
                                                    )).endIf()}
                                                </Button>
                                            </Space.Compact>
                                        </Form.Item>
                                        <Form.Item label="验证码" labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
                                            <TextInput value={emailCode} onChange={setEmailCode} onPressEnter={reqCheckEmailCode} type="emailCode"/>
                                        </Form.Item>
                                        <Row type="flex" justify="space-around" align="middle">
                                            <Button type="primary" onClick={reqCheckEmailCode}>确认邮箱</Button>
                                        </Row>
                                    </>).elseIf(current === 2).then(() => <>
                                        <Form.Item label="密码" labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
                                            <TextInput value={password} onChange={setPassword} onPressEnter={reqRegister} type="password"/>
                                        </Form.Item>
                                        <Row type="flex" justify="space-around" align="middle">
                                            <Button onClick={prev}>修改邮箱</Button>
                                            <Button type="primary" onClick={reqRegister}>注册</Button>
                                        </Row>
                                    </>).endIf()}
                                </Form>
                            </Space>
                        </Card>
                    </Spin>
                </Row>
            </Col>
        </Row>
    )
}