import DefaultModalButton from "../../../../components/default-modal-button";
import {downBot} from "../../../../api";

const formConfig = [
    {key: 'id', hidden: true},
]

export default function DownButton(props) {
    return <DefaultModalButton
        {...props}
        value="下线"
        formConfig={formConfig}
        updateApi={downBot}
        buttonType="primary"
        danger
    />
}