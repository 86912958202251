import {animated, useSpring} from '@react-spring/web';

export default function AnimatedComponent(props) {
    const spring = useSpring({
        from: { opacity: 0, y: -20 },
        to: { opacity: 1, y: 0 },
        config: {duration: 200, friction: 50}
    });

    // const animatedItems = trails.map((trail, index) => {
    //     const item = items[index]
    //     const over = {
    //         label: <animated.span style={{display: "inline-block", ...trail}}>{item.label}</animated.span>,
    //         icon: <animated.span style={{display: "inline-block", verticalAlign: 0, ...trail}}>{item.icon}</animated.span>,
    //     }
    //     return Object.assign({}, item, over);
    // })

    return (
        <animated.div style={spring} className="animated-component">
            {props.children}
        </animated.div>
    );
};