import {useParams} from "react-router-dom";
import {useEffect} from "react";

export default function JumpDynamic() {
    const { type, externalId } = useParams();
    console.info("?")
    useEffect(() => {
        window.location.assign(`https://api.bot.tilitili.club/pub/dynamic/jump/${type}/${externalId}`)
    }, [type, externalId])
    return <></>
}