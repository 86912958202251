import {useState} from "react";
import {Card, Image, Input, Space, Spin, Tag, Typography} from "antd";
import {addMusic, searchMusic} from "../../../api";
import {For, If, isNotNull} from "../../../utils/HtmlUtils";

export default function MusicSearch() {
    const [searchKey, setSearchKey] = useState("");
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const {songList, playerMusicList, playerMusicListDTO} = data;

    const onSearch = () => {
        setLoading(true);
        searchMusic({searchKey}).then(setData).finally(() => {
            setLoading(false);
        })
    }

    const onImportSong = (song) => {
        onImport({songList: [song]});
    }

    const onImportMusic = (music) => {
        onImport({playerMusicList: [music]});
    }

    const onImportList = (list) => {
        onImport({playerMusicListDTO: list});
    }

    const onImport = (data) => {
        setLoading(true);
        addMusic(data).finally(() => {
            setLoading(false);
        });
    }

    return <Spin spinning={loading}>
        <Input.Search placeholder="歌名或链接" value={searchKey} onChange={e=>setSearchKey(e.target.value)} onSearch={onSearch} style={{paddingLeft: 16, paddingRight: 16}}/>
        {If (isNotNull(songList)).then(() => For (songList).then((song, index) => (
            <Card size="small" key={index} onClick={onImportSong.bind(this, song)}>
                <Typography.Title level={5} style={{marginTop: 0}} strong={false}>{song.name}</Typography.Title>
                <Typography.Text type="secondary" style={{fontSize: 10}}>
                    {If (song.fee === 1).then(() => <Tag color="success" bordered={false} >VIP</Tag>).endIf()}
                    {If (isNotNull(song.noCopyrightRcmd)).then(() => <Tag color="warning" bordered={false} >下架</Tag>).endIf()}
                    {song.ownerList.map(owner => owner.name).reduce((a,b)=>a+"/"+b)} -
                    {song.album?.name}
                </Typography.Text>
            </Card>
        ))).elseIf(isNotNull(playerMusicList)).then(() => For (playerMusicList).then((music, index) => (
            <Card size="small" key={index} onClick={onImportMusic.bind(this, music)}>
                <Space>
                    <Image width={50} height={50} src={music?.icon} preview={false} className="listIcon"/>
                    <Typography.Paragraph ellipsis={{rows: 3}}>{music?.name}</Typography.Paragraph>
                </Space>
            </Card>
        ))).elseIf(isNotNull(playerMusicListDTO)).then(() => (
            <Card size="small" onClick={onImportList.bind(this, playerMusicListDTO)}>
                <Space>
                    <Image width={50} height={50} src={playerMusicListDTO?.icon} preview={false} className="listIcon"/>
                    <Typography.Paragraph ellipsis={{rows: 3}}>{playerMusicListDTO?.name}</Typography.Paragraph>
                </Space>
            </Card>
        )).endIf()}
    </Spin>
}