import {Slider} from "antd";
import {useEffect, useState} from "react";

export default function UpdateSlider(props) {
    const [volume, setVolume] = useState(props.defaultValue);

    useEffect(() => {
        setVolume(props.defaultValue);
    }, [props.defaultValue]);

    const onChange = (volume) => {
        setVolume(volume);
        props.onChange(volume);
    }

    const onChangeComplete = volume => {
        setVolume(volume);
        props.onChangeComplete(volume);
    }

    return <Slider {...props} value={volume} onChange={onChange} onChangeComplete={onChangeComplete} />
}