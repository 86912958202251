import DefaultModalButton from "../../../../components/default-modal-button";
import {editBot} from "../../../../api";

const formConfig = [
    {key: 'botId', hidden: true, selectedIndex: "id"},
    {label: '机器人类型', key: 'type', hidden: true},
    {label: '机器人昵称', key: 'name', type: 'input'},
    {label: '请求地址', key: 'host', type: 'input', groupBy: {type: ['mirai', 'gocq', 'minecraft']}},
    {label: '秘钥', key: 'verifyKey', type: 'input'},
    {label: 'botQQ', key: 'qq', type: 'input', groupBy: {type: 'mirai'}},
    {label: '默认功能', key: 'defaultTaskIdList', type: 'multipleInputSelect', resource: 'botTaskResource'}
]

export default function EditBotRobot(props) {
    return <DefaultModalButton
        {...props}
        value="编辑"
        formConfig={formConfig}
        disabled={props.selectedRows.length !== 1}
        updateApi={editBot}
    />
}