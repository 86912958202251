import {Card, Col, Divider, Layout, Row, Space, Typography} from "antd";
import {changeViewByUserAgent, isBlank, isNotNull} from "../../../../utils/HtmlUtils";
import {LeftOutlined} from "@ant-design/icons";
import useJumper from "../../../../components/UseJumper";
import {useEffect, useState} from "react";
import {getBindCode} from "../../../../api";
import LoginCheck from "../../../../components/LoginCheck";

const { Header, Content } = Layout;

export default function UserBind({user, onUserLogin}) {
    const {jumpBack} = useJumper()

    const [bindCode, setBindCode] = useState(null);

    useEffect(() => {
        if (isNotNull(user?.id)) {
            getBindCode().then(setBindCode)
        }
    }, [user?.id])

    return <LoginCheck user={user} onUserLogin={onUserLogin}>
        {changeViewByUserAgent({
            mobile: <Layout>
                <Header>
                    <Row gutter={[10, 0]}>
                        <Col><LeftOutlined onClick={jumpBack}/></Col>
                        <Col>
                            <Typography.Text>账号绑定</Typography.Text>
                        </Col>
                        <Col offset={4}>
                        </Col>
                    </Row>
                </Header>
                <Content>
                    <Space direction="vertical" size="middle" style={{ display: 'flex', paddingTop: 8 }}>
                        <Card size="small">
                            复制
                            <Typography.Text copyable={{text: `绑定码 ${bindCode}`}}>绑定码</Typography.Text>
                            前往以下平台@bot发送即可绑定
                        </Card>
                        <Card size="small">
                            <Row justify="space-between"><Col>群聊</Col><Col>{isBlank(user?.qq, "未绑定")}</Col></Row>
                            <Divider />
                            <Row justify="space-between"><Col>频道</Col><Col>{isBlank(user?.tinyId, "未绑定")}</Col></Row>
                            <Divider />
                            <Row justify="space-between"><Col>Kook</Col><Col>{isBlank(user?.kookUserId, "未绑定")}</Col></Row>
                            <Divider />
                            <Row justify="space-between"><Col>频道2</Col><Col>{isBlank(user?.qqGuildUserId, "未绑定")}</Col></Row>
                            <Divider />
                            <Row justify="space-between"><Col>群聊</Col><Col>{isBlank(user?.qqMemberOpenid, "未绑定")}</Col></Row>
                        </Card>
                    </Space>
                </Content>
            </Layout>,
            pc: null,
        })}
    </LoginCheck>
}