import DefaultModalButton from "../../../../components/default-modal-button";
import {changeStatus} from "../../../../api";

const formConfig = [
    {key: 'id', hidden: true},
    {label: '状态', key: 'status', type: 'select', resource: 'userStatusList'},
]

export default function EditBotUser(props) {
    return <DefaultModalButton
        {...props}
        value="拉黑"
        formConfig={formConfig}
        disabled={props.selectedRows.length !== 1}
        updateApi={changeStatus}
    />
}