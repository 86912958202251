import React from "react";
import {Switch} from "antd";
import {updateMenuMapping} from "../../../../api";
import {emptyFunc, isNull} from "../../../../utils/HtmlUtils";

export default function UpdateRoleMapping(props) {
    const {selectedRows={}, columnsConfig={}, onSuccess=emptyFunc} = props;
    const selectedRow = selectedRows[0] || {};
    const {key} = columnsConfig;
    const checked = isNull(selectedRow[key], false);

    const handleChange = (checked, e) => {
        updateMenuMapping({menuId: selectedRow.id, roleId: key, checked}).then(onSuccess);
    }

    return (
        <Switch size="small" checked={checked} onChange={handleChange}/>
    )
}