import React, {useRef, useState} from "react";
import { Modal } from "antd";
import "./DragModal.css";
import Draggable from 'react-draggable';
import {emptyFunc} from "../../utils/HtmlUtils";

export default function DragModal(props) {
    const { children, title = "Drag-Modal", wrapClassName = "" } = props;
    const [disabled, setDisabled] = useState(true);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggableRef = useRef(null)

    function setDisabledTrue() {
        setDisabled(true)
    }
    function setDisabledFalse() {
        setDisabled(false)
    }

    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggableRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }

        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    function modalRender(modal) {
        return (
            <Draggable disabled={disabled} bounds={bounds} onStart={onStart}>
                <div ref={draggableRef}>{modal}</div>
            </Draggable>
        )
    }

    const dragTitle = (
        <div
            className="drag_title"
            onMouseOver={setDisabledFalse}
            onMouseOut={setDisabledTrue}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={emptyFunc}
            onBlur={emptyFunc}
            // end
        >
            {title}
        </div>
    )

    return (
        <Modal
            {...props}
            centered={false}
            mask={false}
            wrapClassName={`drag_modal ${wrapClassName}`}
            title={dragTitle}
            modalRender={modalRender}
        >
            {children}
        </Modal>
    );
}