import React, {useCallback, useState} from 'react';
import './MyApp.less';
import {Routes, Route, Navigate} from "react-router-dom";
import Register from "./login/Register";
import Login from "./login/Login";
import Admin from "./admin/Admin";
import Dynamic from "./pub/Dynamic";
import {isNotNull, isNull} from "../utils/HtmlUtils";
import JumpDynamic from "./pub/JumpDynamic";
import UserInfo from "./user/info/UserInfo";
import QuickRegister from "./login/QuickRegister";
import UserBag from "./user/bag/UserBag";
import UserMusic from "./user/music/UserMusic";
import UserSetting from "./user/set/UserSetting";
import UserBind from "./user/set/bind/UserBind";

export default function MyApp() {
    const [user, setUser] = useState(null);

    const updateUser = useCallback((newUser) => {
        if (isNull(user) && isNotNull(newUser)) {
            setUser(newUser);
        } else if (isNotNull(user) && isNull(newUser)) {
            setUser(newUser);
        }
    }, [user]);

    return (
        <Routes>
            <Route path="*" element={<Navigate to="/user" replace />}/>
            <Route path="/login" element={<Login onUserLogin={updateUser} user={user}/>}/>
            <Route path="/QuickRegister" element={<QuickRegister onUserLogin={updateUser} user={user}/>}/>
            <Route path="/register" element={<Register/>}/>

            <Route path="/dynamic/:type/:externalId" element={<Dynamic/>}/>
            <Route path="/dynamic/jump/:type/:externalId" element={<JumpDynamic/>}/>

            <Route path="/admin/:topKey?/:siderKey?/:subKey?" element={<Admin onUserLogin={updateUser} user={user}/>}/>

            <Route path="/user" element={<UserInfo user={user} onUserLogin={updateUser}/>}/>
            <Route path="/user/bag" element={<UserBag user={user} onUserLogin={updateUser}/>}/>
            <Route path="/user/music" element={<UserMusic user={user} onUserLogin={updateUser}/>}/>
            <Route path="/user/setting" element={<UserSetting user={user} onUserLogin={updateUser}/>}/>
            <Route path="/user/setting/bind" element={<UserBind user={user} onUserLogin={updateUser}/>}/>
        </Routes>
    )

}
