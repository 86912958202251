import {listBotSenderBotRobot, updateBotSenderBotRobotIndex} from "../../../../api";
import {
    assignNewObject,
    convertToPrams, If,
    isEmpty,
    isNull,
    suppleTableColumn
} from "../../../../utils/HtmlUtils";
import {useCallback, useEffect, useState} from "react";
import KickTable from "../../../../components/KickTable";
import DefaultModalButton from "../../../../components/default-modal-button";
import {arrayMove} from "@dnd-kit/sortable";

const rowKey = (row) => row.botId
export default function UpdateSenderBotIndexFactory(indexType) {
    const columnsConfig = [
        {title: "id", key: "botId", width: 60},
        {title: '渠道名称', key: 'senderName', width: 150},
        {title: 'Bot名称', key: 'botName', width: 150},
        {title: '机器人类型', key: 'type', width: 150, type: 'choose', chooseMap: 'robotTypeList'},
        If (indexType === "listenIndex").then(() => (
            {title: '优先级(听)', key: 'listenIndex', width: 180, type: 'sorter'}
        )).else(() => (
            {title: '优先级(说)', key: 'sendIndex', width: 180, type: 'sorter'}
        )),
    ];

    return function UpdateSenderBotIndex(props) {
        const {selectedRows, resources, globalProps} = props;
        const disabled = isEmpty(selectedRows) || selectedRows.length > 1;
        const selectedRow = selectedRows[0] || {};
        const senderId = selectedRow.id;

        const [filters, setFilters] = useState({});
        const [sorter, setSorter] = useState({field: indexType, order: 'ascend'});
        const [pagination, setPagination] = useState({pageSize: 10, current: 1});
        const [data, setData] = useState({});
        const [loading, setLoading] = useState(false);
        const {total,pageSize,current,list=[]} = data;

        const updateData = useCallback(() => {
            if (isNull(senderId)) {
                return null;
            }
            const params = convertToPrams(pagination, filters, sorter, {})
            params.senderId = senderId;
            console.info("req data ", params)
            setLoading(true);
            listBotSenderBotRobot(params).then(data => {
                setData(data);
            }).finally(() => setLoading(false))
        }, [senderId, pagination, filters, sorter]);

        useEffect(() => {
            updateData();
        }, [updateData])

        function onDragEnd({ active, over }) {
            if (isNull(over)) {
                return null;
            }
            const activeIndex = list.findIndex((i) => rowKey(i) === active.id);
            const overIndex = list.findIndex((i) => rowKey(i) === over?.id);
            const botIdList = arrayMove(list, activeIndex, overIndex).map(bot => bot.botId);
            updateBotSenderBotRobotIndex({senderId, botIdList, indexType}).then(updateData)
        }

        const setFilter = (key, value) => {
            setFilters(assignNewObject(filters, key, isNull(value)? null: [value]));
        }

        const onChange = (pagination, filters, sorter) => {
            console.info(pagination, filters, sorter);
            setPagination(pagination);
            setFilters(filters);
            setSorter(sorter);
        }

        const paginationProp = {
            pageSize: pageSize,
            total: total,
            current: current,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            pageSizeOptions: ['5', '10', '20', '50', '100'],
            showSizeChanger: true,
            showQuickJumper: true,
        }
        const columns = columnsConfig.map(columnConfig => {
            return suppleTableColumn(columnConfig, {filters, sorter, setFilter, resources, globalProps, loading});
        })
        const title = `值班修改(${indexType === "listenIndex"? "听": "说"})`
        return <span>
            <DefaultModalButton value={title} width={800} onShowModal={updateData} disabled={disabled}>
                <KickTable
                    bordered
                    size='small'
                    rowKey={rowKey}
                    columns={columns}
                    dataSource={list}
                    onDragEnd={onDragEnd}
                    pagination={paginationProp}
                    onChange={onChange}
                />
            </DefaultModalButton>
        </span>
    }
}

