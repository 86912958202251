import React, {useEffect, useState} from "react";
import {Button, Col, Form, Radio, Row} from "antd";
import {LockOutlined} from "@ant-design/icons";
import {isLogin, login} from "../../api";
import TextInput from "../../components/TextInput";
import {isNotNull} from "../../utils/HtmlUtils";
import useJumper from "../../components/UseJumper";

export default function Login({onUserLogin}) {
    const {jumpToQuickRegister, jumpToRegister, jumpToSuccess} = useJumper();
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [remember, setRemember] = useState(false);

    function reqLogin() {
        login({email, password, remember}).then(user => {
            onUserLogin(user);
            if (isNotNull(user)) {
                jumpToSuccess();
            }
        })
    }

    useEffect(() => {
        isLogin().then(user => {
            onUserLogin(user);
            if (isNotNull(user)) {
                jumpToSuccess();
            }
        })
    }, [onUserLogin, jumpToSuccess])

    return (
        <Row type="flex" align="middle" className="max-height">
            <Col span={24}>
                <Row type="flex" justify="space-around">
                    <Col>
                        <LockOutlined className="primary-color font-size-100px"/>
                    </Col>
                </Row>
                <Row type="flex" justify="space-around">
                    <Col flex="300px">
                        <Form layout="horizontal">
                            <Form.Item label="邮箱" labelCol={{ flex: "100px" }} wrapperCol={{ flex: "200px" }}>
                                <TextInput value={email} onChange={setEmail} type="email"/>
                            </Form.Item>
                            <Form.Item label="密码" labelCol={{ flex: "100px" }} wrapperCol={{ flex: "200px" }}>
                                <TextInput value={password} onChange={setPassword} onPressEnter={login} type="password"/>
                            </Form.Item>
                            <Form.Item>
                                <Row type="flex" justify="space-around" align="middle">
                                    <Radio checked={remember} onClick={() => setRemember(!remember)}>记住我</Radio>
                                </Row>
                            </Form.Item>
                            <Form.Item>
                                <Row type="flex" justify="space-around" align="middle">
                                    <Button type="primary" onClick={reqLogin}>登陆</Button>
                                    <Button onClick={jumpToQuickRegister}>注册</Button>
                                    <Button onClick={jumpToRegister}>注册管理员</Button>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}