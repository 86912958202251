import {useEffect, useState} from "react";
import {listRobot} from "../../../../api";
import {Select} from "antd";

export default function BotChoose(props) {
    const {user} = props.globalProps
    const {setFilter, filters} = props;
    const [botList, setBotList] = useState([]);

    useEffect(() => {
        listRobot({pageSize:100, current: 1, status: 0}).then(data => {
            setBotList(data.list);
        });
    }, [user])

    const handleChange = value => {
        setFilter('botId', value)
    }

    const options = botList.map(bot => ({
        value: bot.id, label: bot.name,
    }))
    options.unshift({value: '', label: '全部'})

    return <Select
        value={filters?.botId?.[0] || ''}
        style={{ width: 180, marginLeft: 8 }}
        onChange={handleChange}
        options={options}
    />
}