import {loginOut} from "../../../api";
import {changeViewByUserAgent} from "../../../utils/HtmlUtils";
import useJumper from "../../../components/UseJumper";
import {Avatar, Col, Layout, Row, Typography} from "antd";
import './UserInfo.less'
import {ContainerOutlined, SettingOutlined, TruckOutlined, WarningOutlined} from "@ant-design/icons";
import IconButton from "./IconButton";
import LoginCheck from "../../../components/LoginCheck";

const { Header, Footer, Content } = Layout;

export default function UserInfo({user, onUserLogin}) {
    const {jumpToLogin} = useJumper();

    function reqLoginOut() {
        loginOut().then(() => {
            jumpToLogin("/user")
        })
    }

    return <LoginCheck user={user} onUserLogin={onUserLogin}>
        {changeViewByUserAgent({
            mobile: <Layout>
                <Header>
                    <Row gutter={[10, 0]}>
                        <Col><Avatar size={40} src={user?.face}/></Col>
                        <Col>
                            <Row className="userName"><Typography.Text>{user?.name}</Typography.Text></Row>
                            <Row><Typography.Text>id：{user?.id}</Typography.Text></Row>
                        </Col>
                        <Col>
                            <span onClick={reqLoginOut}>退出登陆</span>
                        </Col>
                    </Row>
                </Header>
                <Content>
                    <Row justify="space-evenly" align="middle">
                        <IconButton name="背包" link="/user/bag" icon={<TruckOutlined />}/>
                        <IconButton name="" icon={<WarningOutlined />}/>
                        <IconButton name="歌单" link="/user/music" icon={<ContainerOutlined />}/>
                        <IconButton name="设置" link="/user/setting" icon={<SettingOutlined />}/>
                    </Row>
                </Content>
                <Footer></Footer>
            </Layout>,
            pc: null,
        })}
    </LoginCheck>
}