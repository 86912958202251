import {useLocation, useNavigate} from "react-router-dom";
import {isBlank} from "../utils/HtmlUtils";
import {useCallback} from "react";

export default function useJumper() {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const ref = searchParams.get("ref");

    const jumpToSuccess = useCallback((cusPath) => {
        navigate(isBlank(cusPath, isBlank(ref, "/")))
    }, [navigate, ref])

    const jumpToLogin = useCallback((cusPath) => {
        console.log("jump to login", cusPath)
        navigate("/login?ref="+isBlank(cusPath, ref))
    }, [navigate, ref])

    const jumpToRegister = useCallback((cusPath) => {
        console.log("jump to register", cusPath)
        navigate("/register?ref="+isBlank(cusPath, ref));
    }, [navigate, ref])

    const jumpToQuickRegister = useCallback((cusPath) => {
        console.log("jump to quick register", cusPath)
        navigate("/QuickRegister?ref="+isBlank(cusPath, ref));
    }, [navigate, ref])

    const jumpBack = useCallback(() => {
        navigate(-1);
    }, [navigate])

    return {
        jumpToLogin,
        jumpToRegister,
        jumpToQuickRegister,
        jumpToSuccess,
        jumpBack,
    };

}