import DragModal from "../drag-modal/DragModal";
import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Typography, Upload} from "antd";
import {useState} from "react";
import {
    assignNewObject,
    checkResp,
    emptyFunc,
    For,
    If,
    InputSelect,
    isBlank, isNotBlank,
    isNotNull,
    isNull, MultipleInputSelect
} from "../../utils/HtmlUtils";
import {uploadFileToOSSUrl} from "../../api";
import { UploadOutlined } from '@ant-design/icons';

export default function DefaultModalButton(props) {
    const {
        value,
        buttonStyle,
        onSuccess=emptyFunc,
        formConfig=[],
        modalRender,
        updateApi,
        resources,
        col=1,
        selectedRows=[],
        disabled=false,
        danger=false,
        buttonType,
        children,
        onShowModal=emptyFunc,
    } = props;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({});

    const selectedRow = selectedRows[0] || {};

    function showModal() {
        const newState = {};
        for (const config of formConfig) {
            console.info(config.key, selectedRow)
            const selectedIndex = isNull(config.selectedIndex)? config.key: config.selectedIndex;
            newState[config.key] = isNull(config.value)? selectedRow[selectedIndex]: config.value;
            newState[config.key] = isBlank(newState[config.key])? null: newState[config.key];
        }
        setOpen(true);
        setState(newState);
        onShowModal();
    }
    function closeModal() {
        setOpen(false);
    }

    async function handleOk() {
        const params = {};
        for (const config of formConfig) {
            console.info(config)
            if (!isNull(config.submit, true)) {
                continue;
            }
            const isTheGroup = isGroupBy(config.groupBy, state);
            if (!isTheGroup) {
                continue;
            }
            if (config.type === 'datePicker') {
                if (isNotNull(params[config.key] = state[config.key])) {
                    params[config.key] = state[config.key].format('YYYY-MM-DD hh:mm:ss');
                }
            } else {
                params[config.key] = state[config.key];
            }
        }
        // 如果不是表单模态框，也不需要自定义提交，就是点击确定直接关闭模态框，否则设置按钮loading，等后台返回成功再关
        if (isNotNull(modalRender)) {
            closeModal();
        }
        try {
            setLoading(true);
            const data = await updateApi(params);
            onSuccess(data);
            if (isNull(modalRender)) {
                closeModal();
            }
        } finally {
            setLoading(false);
        }
    }

    function handleChange(key, value) {
        setState(assignNewObject(state, key, isBlank(value)? null: value))
    }

    return (
        <span>
            <Button
                style={buttonStyle || { marginLeft: 8 }}
                onClick={showModal}
                loading={loading}
                disabled={disabled}
                danger={danger}
                type={buttonType}>
                {value || '编辑'}
            </Button>
            <DragModal
                confirmLoading={loading}
                title={value || '编辑'}
                open={open}
                onOk={handleOk}
                onCancel={closeModal}
                width={props.width || (col * 400)}
            >
                {If(isNotNull(props.modalRender)).then(() => (
                    props.modalRender(state)
                )).elseIf(isNotNull(children)).then(() => (
                    children
                )).else(() => (
                    converseToForm({formConfig, params:state, resources, col, size:'default', onChange:handleChange})
                ))}
            </DragModal>
        </span>
    )
}
const isGroupBy = (groupBy, params) => isNull(groupBy) || For(groupBy).every((key, value) => (Array.isArray(value)? value: [value]).includes(params[key]))

export const converseToForm = ({formConfig=[], params={}, resources={}, col=1, size='default', onChange=emptyFunc}) => (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} layout="horizontal" size='small'>
        <Row gutter={24}>
            {For(formConfig).if(config => !config.hidden).flatThen(config => (
                converseToFormItem(config, params, resources, col, size, config.key, onChange)
            )).filter(isNotNull)}
        </Row>
    </Form>
)

const converseToFormItem = ({label,type,key,disabled,placeholder,resource,min,max,groupBy,mode,suffix,autoSize,col:itemCol=1,render,rows,accept}, params={}, resources={}, col=1, size='default', listKey=Math.random(), onChange=emptyFunc) => (
    If(isGroupBy(groupBy, params)).then(() => (
        <Col span={24 * itemCol / col} key={listKey}>
            <Form.Item label={label} style={size === 'small'? {margin: 0}: {}}>
                {If(type === 'input').then(() => (
                    <Input value={params[key]} disabled={disabled} onChange={e => onChange(key, e.target.value)} placeholder={placeholder} suffix={suffix}/>
                )).elseIf(type === 'inputSelect').then(() => (
                    <InputSelect resource={resources[resource]} value={params[key]} disabled={disabled} onChange={value => onChange(key, value)}/>
                )).elseIf(type === 'multipleInputSelect').then(() => (
                    <MultipleInputSelect resource={resources[resource]} value={params[key]} disabled={disabled} onChange={value => onChange(key, value)}/>
                )).elseIf(type === 'select').then(() => (
                    <Select value={(params[key])} disabled={disabled} onChange={value => onChange(key, value)} allowClear>
                        {For(resources[resource]).then((item, index) => (
                            <Select.Option key={index} value={(item.value)}>{item.text}</Select.Option>
                        ))}
                    </Select>
                )).elseIf(type === 'datePicker').then(() => (
                    <DatePicker value={params[key]} showTime={true} onChange={value => onChange(key, value)} />
                )).elseIf(type === 'textArea').then(() => (
                    <Input.TextArea value={params[key]} disabled={disabled} onChange={e => onChange(key, e.target.value)} placeholder={placeholder} autoSize={autoSize} rows={rows}/>
                )).elseIf(type === 'inputNumber').then(() => (
                    <InputNumber value={params[key]} disabled={disabled} onChange={value => onChange(key, value)} placeholder={placeholder} min={min} max={max} suffix={suffix}/>
                )).elseIf(type === 'inputGroup').then(() => (
                    render(params[key], value => onChange(key, value))
                )).elseIf(type === 'text').then(() => (
                    <Typography.Text>{params[key]}</Typography.Text>
                )).elseIf(type === 'upload').then(() => <>
                    <Upload name="file" accept={accept} headers={{'X-Requested-With':null}}
                            withCredentials={true} action={uploadFileToOSSUrl} showUploadList={accept !== 'wav'}
                            onChange={info => If(info.file.status === 'done').then(
                        onChange(key, checkResp(info.file.response).data)
                    )}>
                        <Button icon={<UploadOutlined/>}>上传</Button>
                    </Upload>
                    {If(isNotBlank(params[key])).then(() => <>
                        <Button onClick={() => onChange(key, "")}>删除</Button>
                        {If(accept === 'wav').then(() => (
                            <audio controls>
                                <source src={params[key]} type="audio/wav"/>
                                <embed height="50" width="100" src={params[key]}/>
                            </audio>
                        )).endIf()}
                    </>).endIf()}
                </>).endIf()}
            </Form.Item>
        </Col>
    )).endIf()
)