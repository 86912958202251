import Control from "./Control";
import {useCallback, useEffect, useState} from "react";
import {musicFileStream, lastMusic, updateMusicVolume, prevMusic} from "../../../api";
import {If, isNotNull, isNull, log, nullFunc} from "../../../utils/HtmlUtils";
import {Howl} from 'howler'
import useDebounce from "../../../components/UseDebounce";

const getLocalVolume = () => {
    return parseFloat(isNull(localStorage.getItem('volume'), '50'));
}
const setLocalVolume = (volume) => {
    if (isNull(volume)) {
        localStorage.removeItem('volume');
    }
    localStorage.setItem('volume', volume.toString());
}
const getLocalMusicId = () => {
    return localStorage.getItem('musicId');
}
const setLocalMusicId = (musicId) => {
    if (isNull(musicId)) {
        localStorage.removeItem('musicId');
    }
    localStorage.setItem('musicId', musicId.toString());
}

const oldVolume = getLocalVolume();
if (oldVolume < 1 && oldVolume > 0) {
    localStorage.removeItem('volume');
}

export default function LocalControl({playFlag, user}) {
    // const location = useLocation();
    const listId = playFlag?.listId;

    const [isPlaying, setIsPlaying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [volumeLoading, setVolumeLoading] = useState(false);
    const [theMusic, setTheMusic] = useState(null);
    // const [playerMusicId, setPlayerMusicId] = useState(null);
    const [playerMusicId, debounceLoading] = useDebounce(theMusic?.id, 1300);
    const [sound, setSound] = useState(null);
    // const [volume, setVolume] = useState(getLocalVolume());
    const [seek, setSeek] = useState(0);

    const localVolume = getLocalVolume();
    const theVolume = theMusic?.volume;
    const useVolume = isNull(theVolume, localVolume);

    const reqLast = useCallback((musicId) => {
        setLoading(true)
        lastMusic({listId, musicId}).then(theMusic => {
            setTheMusic(theMusic);
        }).finally(() => setLoading(false));
    }, [listId]);

    const reqPrev = () => {
        setLoading(true)
        prevMusic().then(theMusic => {
            setTheMusic(theMusic);
        }).finally(() => setLoading(false));
    }

    useEffect(()=> {
        reqLast(getLocalMusicId());
    }, [reqLast])

    useEffect(() => {
        if (isNotNull(theMusic?.id)) {
            setLocalMusicId(theMusic?.id);
        }
    }, [theMusic?.id])

    useEffect(() => {
        if (isNull(playerMusicId)) {
            return;
        }
        const params = {
            src: [musicFileStream({musicId: playerMusicId, userId: user?.id})],
            preload: false,
            autoplay: true,
            format: ['mp3', 'aac'],
            volume: 0,
            pool: 1,
            onend: reqLast.bind(this, null),
            onplay: () => setLoading(false),
            onloaderror: () => {
                log.info("加载失败");
                setLoading(false);
                setIsPlaying(false);
                sound.unload();
            },
            onplayerror: () => {
                log.info("播放失败");
                setLoading(false);
                setIsPlaying(false);
                sound.unload();
            },
        }
        // console.log("isPhone", os.isPhone);
        // const sound = If (os.isPhone)
        //     .then(() => new Howl(Object.assign(params, {xhr: {withCredentials: true}})))
        //     .else(() => new Howl(Object.assign(params, {html5: true})));
        const sound = new Howl(Object.assign(params, {html5: true}));
        setSound(sound)
        return () => {
            sound.unload();
            setSound(null);
        }
    }, [user?.id, playerMusicId, reqLast])

    useEffect(() => {
        if (isNotNull(sound)) {
            sound.volume(useVolume / 200);
        }
    }, [sound, useVolume])

    useEffect(() => {
        if (isNull(sound)) {
            return;
        }
        if (isPlaying) {
            setLoading(true)
            if (sound.state() === 'unloaded') {
                sound.load();
                // sound.play();
            } else {
                sound.play();
            }
        } else {
            if (sound.state() === 'loaded') {
                sound.pause();
            }
        }
    }, [sound, isPlaying])

    useEffect(() => {
        // 每秒钟读取一次音频当前的进度
        const intervalId = setInterval(() => {
            setSeek(Math.round(sound?.seek() || 0));
        }, 1000);

        return () => {
            clearInterval(intervalId);
        }
    }, [sound])

    const onStop = () => {
        setIsPlaying(false);
    }

    const onStart = () => {
        setIsPlaying(true);
    }

    const onLast = () => {
        reqLast(null);
    }

    const onPrev = () => {
        reqPrev();
    }

    const onShowQueue = () => {
    }

    const onSeekChange = seek => {
        sound.seek(seek);
        setSeek(seek);
    }

    const onVolumeChange = volume => {
        sound.volume(volume / 200);
    }

    const onVolumeChangeComplete = volume => {
        if (isNull(theMusic?.volume)) {
            setLocalVolume(volume);
        } else {
            setVolumeLoading(true);
            updateMusicVolume({musicId: theMusic?.id, volume}).then(() => {
                theMusic.volume = volume;
            }).finally(() => setVolumeLoading(false));
        }
    }

    const onCustomChange = checked => {
        if (checked) {
            setVolumeLoading(true);
            updateMusicVolume({musicId: theMusic?.id, volume: useVolume}).then(() => {
                theMusic.volume = useVolume;
            }).finally(() => setVolumeLoading(false));
        } else {
            setVolumeLoading(true);
            updateMusicVolume({musicId: theMusic?.id}).then(() => {
                theMusic.volume = null;
            }).finally(() => setVolumeLoading(false));
        }
    }

    return <Control theMusic={theMusic}
                    isPlaying={isPlaying}
                    loading={loading || debounceLoading}
                    onStart={onStart}
                    onStop={onStop}
                    onLast={onLast}
                    onShowQueue={onShowQueue}
                    onPrev={onPrev}
                    volume={{
                        defaultValue: useVolume,
                        onChange: onVolumeChange,
                        onChangeComplete: onVolumeChangeComplete,
                        useCustomVolume: isNotNull(theMusic?.volume),
                        onCustomChange,
                        volumeLoading
                    }}
                    seek={If (isNotNull(sound)).then(() => ({
                        max: Math.ceil(sound.duration()),
                        value: seek,
                        onChange: onSeekChange,
                        formatter: seek => {
                            const minutes = Math.floor(seek / 60) || 0;
                            const seconds = (seek - minutes * 60) || 0;

                            return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
                        },
                        tooltip: {open: false},
                    })).else(nullFunc)}
    />
}