import DefaultModalButton from "../../../../components/default-modal-button";
import {deleteBotMenu} from "../../../../api";

const formConfig = [
    {key: 'id', hidden: true},
    {key: 'name', submit: false},
]

export default function DeleteBotMenu(props) {
    const modalRender = state => {
        return <p>
            {`确定删除[${state.name}]吗?`}
        </p>
    }

    return <DefaultModalButton
        {...props}
        value="删除"
        formConfig={formConfig}
        updateApi={deleteBotMenu}
        disabled={props.selectedRows.length !== 1}
        modalRender={modalRender}
    />
}