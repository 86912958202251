import DefaultModalButton from "../../../../components/default-modal-button";
import {addBot} from "../../../../api";

const formConfig = [
    {label: '机器人类型', key: 'type', type: 'select', resource: 'robotTypeList'},
    {label: '机器人昵称', key: 'name', type: 'input', groupBy: {type: 'minecraft'}},
    {label: '上报方式', key: 'pushType', type: 'select', resource: 'pushTypeList', groupBy: {type: ['mirai']}},
    {label: '请求地址', key: 'host', type: 'input', groupBy: {type: ['mirai', 'gocq', 'minecraft']}},
    {label: '秘钥', key: 'verifyKey', type: 'input'},
    {label: 'botQQ', key: 'qq', type: 'input', groupBy: {type: 'mirai'}},
]

export default function AddBotRobot(props) {
    return <DefaultModalButton
        {...props}
        selectedRows
        value="新增"
        formConfig={formConfig}
        updateApi={addBot}
    />
}