import {Col, Row} from "antd";
import {useNavigate} from "react-router-dom";

export default function IconButton({icon, name, link}) {
    const navigate = useNavigate();

    const jumpToLink = () => {
        navigate(link)
    }

    return <Col onClick={jumpToLink}>
        <Row justify="space-around" align="middle">
            <Col style={{width: '40px', fontSize: '40px'}}>{icon}</Col>
        </Row>
        <Row justify="space-around" align="middle"><Col>{name}</Col></Row>
    </Col>
}