import {Button, Dropdown, Layout, Menu, message, Tabs, Typography} from "antd";
import {useCallback, useEffect, useState} from "react";
import {getMenuList, isLogin, loginOut} from "../../api";
import {MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import {assignNewObject, If, isNotEmpty, isNotNull, isNull} from "../../utils/HtmlUtils";
import {componentConfig, suppleMenuIcon} from "../../config/menuConfig";
import AnimatedMenu from "../../components/AnimatedMenu";
import AnimatedComponent from "../../components/AnimatedComponent";
import useJumper from "../../components/UseJumper";

const { Header, Sider, Content } = Layout;

function hsMenu(menuList, selectedSiderKey) {
    for (const menu of menuList) {
        if (selectedSiderKey.includes(menu.key)) {
            return true
        }
        if (isNotNull(menu.children)) {
            if (hsMenu(menu.children, selectedSiderKey)) {
                return true;
            }
        }
    }
    return false;
}

const menuMap = {};
export default function Admin({onUserLogin, user }) {
    const {jumpToLogin, jumpToSuccess} = useJumper();
    const navigate = useNavigate();
    const { topKey, siderKey, subKey } = useParams();

    const [menuList, setMenuList] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKeyList, setSelectedKeyList] = useState([]);

    const [bot, setBot] = useState(null);

    const selectedTopKey = topKey;
    const selectedSiderKey = [topKey, siderKey, subKey].filter(isNotNull).join("/");
    const hasMenu = hsMenu(menuList, selectedSiderKey);

    const setToMenuMap = useCallback((menuList) => {
        menuList?.forEach(menu => {
            menuMap[menu.key] = menu
            setToMenuMap(menu.children);
        })
    }, []);

    // 加载菜单
    useEffect(() => {
        if (isNull(user)) {
            isLogin().then(user => {
                onUserLogin(user);
                if (isNull(user)) {
                    jumpToLogin("/admin/"+selectedSiderKey)
                }
                if (user.isAdmin !== true) {
                    message.warning("权限不足");
                    jumpToSuccess()
                }
            }).catch(() => {
                jumpToLogin("/admin/"+selectedSiderKey)
            });
        }
    }, [onUserLogin, selectedSiderKey, user, jumpToLogin, jumpToSuccess]);

    useEffect(() => {
        if (isNotNull(user)) {
            getMenuList().then(menuList => {
                suppleMenuIcon(menuList);
                setMenuList(menuList);
                setToMenuMap(menuList);
            })
        }
    }, [user, setToMenuMap])

    useEffect(() => {
        const deep = selectedSiderKey?.split("/").length
        if (deep > 1 && !selectedKeyList.includes(selectedSiderKey)) {
            setSelectedKeyList([selectedSiderKey, ...selectedKeyList]);
        }
    }, [selectedSiderKey, selectedKeyList])

    function toggleCollapsed() {
        setCollapsed(!collapsed);
    }

    function menuChangeEvent(e) {
        if (e.key.startsWith("bot") && isNull(bot)) {
            message.error("请先选中Bot");
            return;
        }
        menuChange(e.key)
    }

    function menuChange(key) {
        navigate(`/admin/${key}`)
    }

    function reqLoginOut() {
        loginOut().then(() => {
            jumpToLogin("/admin/"+selectedSiderKey)
        })
    }

    function onEdit(targetKey) {
        setSelectedKeyList(selectedKeyList.filter(key => key !== targetKey));
        navigate(`/admin/${topKey}`)
    }

    const userMenu = {
        items: [
            {key: 'name', label: user?.username, disabled: true},
            {key: 'loginOut', label: (
                <span onClick={reqLoginOut}>退出登陆</span>
            )}
        ]
    }

    if (isNotNull(menuMap) && isNotNull(menuMap['bot'])) {
        menuMap['bot'].label = `${bot?.name || 'Bot'}配置`;
    }
    const topMenuList = menuList?.map(menu => assignNewObject(menu, "children", null))
    const siderMenuList = menuMap[selectedTopKey]?.children;
    const tabs = selectedKeyList.map(key => menuMap[key]).filter(isNotNull).filter(menu => isNull(menu.children));
    const tabsWithComponent = tabs.map(menu => {
        const Component = componentConfig[menu.key];
        if (isNotNull(Component)) {
            if (menu.key.startsWith("bot") && isNull(bot)) {
                message.error("请先选中Bot");
                return {...menu, children: <AnimatedComponent><div/></AnimatedComponent>}
            }
            return {...menu, children: <AnimatedComponent><Component
                    bot={bot} onBotChange={setBot}
                /></AnimatedComponent>}
        }
        return menu
    })

    return (
        <Layout style={{minHeight: "100vh"}}>
            <Header style={{background: "white", height: 'auto', lineHeight: '46px'}}>
                <div style={{float: "left", width: "150px"}}>
                    <Typography.Title level={2} strong={false} style={{margin: "0px"}} className="primary-color">
                        笨蛋云
                    </Typography.Title>
                </div>
                <Menu mode="horizontal" className="header-menu" style={{width: '40%', float: 'left'}}
                      selectedKeys={selectedTopKey} items={topMenuList} onClick={menuChangeEvent}/>
                <Dropdown menu={userMenu} placement="bottomRight" arrow={{ pointAtCenter: true }}
                          overlayClassName="user-dropdown">
                    <div style={{float: "right"}}>
                        <UserOutlined />
                    </div>
                </Dropdown>
            </Header>
            <Layout>
                {If(isNotEmpty(siderMenuList)).then(() => (
                    <Sider width={200} collapsed={collapsed} theme="light">
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            <Button onClick={toggleCollapsed} type="link">
                                {collapsed? <MenuUnfoldOutlined className="primary-color"/>: <MenuFoldOutlined className="primary-color"/>}
                            </Button>
                        </div>
                        <AnimatedMenu items={siderMenuList} mode="inline" selectedKeys={selectedSiderKey} onClick={menuChangeEvent}/>
                    </Sider>
                )).endIf()}
                <Layout>
                    <Content>
                        {/*<DefaultModalButton width={800}>*/}
                        {/*    <KickTable/>*/}
                        {/*</DefaultModalButton>*/}
                        {If(hasMenu).then(() => (
                            <Tabs type="editable-card"
                                  style={{marginTop:10,marginLeft:5,marginRight:5,}}
                                  hideAdd={true}
                                  onChange={menuChange}
                                  activeKey={selectedSiderKey}
                                  onEdit={onEdit}
                                  items={tabsWithComponent}
                            />
                        )).endIf()}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}