import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {SortableContext, useSortable, verticalListSortingStrategy,} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table } from 'antd';
import React from 'react';
import {defaultRowKey, emptyFunc} from "../utils/HtmlUtils";

const Row = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props['data-row-key'],
    });

    const style = {
        ...props.style,
        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
        transition,
        cursor: 'move',
        ...(isDragging ? { position: 'relative', zIndex: 999999 } : {}),
    };

    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

export default function KickTable(props) {
    const {columns=[], dataSource=[], onDragEnd=emptyFunc, rowKey=defaultRowKey} = props;

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );

    // const onDragEnd = ({ active, over }) => {
    //     if (active.id !== over?.id) {
    //         setDataSource((prev) => {
    //             const activeIndex = prev.findIndex((i) => i.key === active.id);
    //             const overIndex = prev.findIndex((i) => i.key === over?.id);
    //             return arrayMove(prev, activeIndex, overIndex);
    //         });
    //     }
    // };

    return (
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
                // rowKey array
                items={dataSource.map(rowKey)}
                strategy={verticalListSortingStrategy}
            >
                <Table
                    {...props}
                    components={{
                        body: {
                            row: Row,
                        },
                    }}
                    columns={columns}
                    dataSource={dataSource}
                />
            </SortableContext>
        </DndContext>
    );
};