import DefaultTable from "../../../../components/default-table";
import {getResources, listBotSender} from "../../../../api";
import {useEffect, useMemo, useState} from "react";
import UpdateSenderTaskSwitch from "./UpdateRenderTask";
import {isNull} from "../../../../utils/HtmlUtils";
import UpdateSenderBotListenIndex from "./UpdateSenderBotListenIndex";
import UpdateSenderBotSendIndex from "./UpdateSenderBotSendIndex";
import BotChoose from "./BotChoose";

const buttons = [BotChoose, UpdateSenderBotListenIndex, UpdateSenderBotSendIndex]
const needResourcesList=['sendTypeResource', 'robotTypeList']
export default function BotSenderManager(props) {
    const [columnsConfig, setColumnsConfig] = useState([]);

    useEffect(() => {
        getResources({needResourcesList: ['botTaskResource']}).then(resources => {
            setColumnsConfig([
                {title: "id", key: "id", width: 60, type: "search"},
                {title: '名称', key: 'name', width: 150, type: 'search'},
                {title: '类型', key: 'sendType', width: 90, type: 'choose', chooseMap: 'sendTypeResource'},
                {title: '值班者(听)', key: 'listenBot', width: 180},
                {title: '值班者(说)', key: 'sendBot', width: 180},
                ...isNull(resources.botTaskResource, []).map(r => ({
                    title: r.text,
                    key: r.value+"",
                    width: 80,
                    type: 'switch',
                    theEllipsis: true,
                    switch: UpdateSenderTaskSwitch,
                }))
            ])
        })
    }, [])

    const defaultFilters = useMemo(() => ({ status: [0] }), []);
    return <DefaultTable
        globalProps={props}
        needResourcesList={needResourcesList}
        buttons={buttons}
        columnsConfig={columnsConfig}
        getDataApi={listBotSender}
        defaultFilters={defaultFilters}
        defaultPagination={{pageSize: 10, current: 1}}
    />
}