import React, { useState, useEffect } from 'react';

function CountdownPrivate({ duration }) {
    const [timeLeft, setTimeLeft] = useState(duration);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(prevSeconds => prevSeconds - 1);
        }, 1000);
        return () => clearInterval(interval)
    }, [duration]);

    return <>{timeLeft}</>;
}

function Countdown({ duration, disable }) {
    return disable ? <CountdownPrivate duration={duration}/> : null;
}

export default Countdown;