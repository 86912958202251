import {Card, Col, Layout, Row, Space, Typography} from "antd";
import {changeViewByUserAgent} from "../../../utils/HtmlUtils";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import useJumper from "../../../components/UseJumper";
import {useNavigate} from "react-router-dom";
import LoginCheck from "../../../components/LoginCheck";

const { Header, Content } = Layout;

export default function UserSetting({user, onUserLogin}) {
    const {jumpBack} = useJumper()
    const navigate = useNavigate();

    const jumpToUserBind = () => {
        navigate("/user/setting/bind")
    }

    return <LoginCheck user={user} onUserLogin={onUserLogin}>
        {changeViewByUserAgent({
            mobile: <Layout>
                <Header>
                    <Row gutter={[10, 0]}>
                        <Col><LeftOutlined onClick={jumpBack}/></Col>
                        <Col>
                            <Typography.Text>设置</Typography.Text>
                        </Col>
                        <Col offset={4}>
                        </Col>
                    </Row>
                </Header>
                <Content>
                    <Space direction="vertical" size="middle" style={{ display: 'flex', paddingTop: 8 }}>
                        <Card size="small">
                            <Row justify="space-between" onClick={jumpToUserBind}>
                                <Col>账号绑定</Col>
                                <Col><RightOutlined /></Col>
                            </Row>
                        </Card>
                    </Space>
                </Content>
            </Layout>,
            pc: null,
        })}
    </LoginCheck>
}