import DefaultModalButton from "../../../../components/default-modal-button";
import {importRandomTalk} from "../../../../api";

const formConfig = [
    {label: '表格', key: 'file', type: 'upload', accept: 'xlsx'},
]

export default function ImportRandomTalk(props) {
    return <DefaultModalButton
        {...props}
        selectedRows
        value="导入"
        formConfig={formConfig}
        updateApi={importRandomTalk}
    />
}