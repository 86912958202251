import DefaultModalButton from "../../../../components/default-modal-button";
import {addBotMenu} from "../../../../api";

const formConfig = [
    {key: 'parentId', hidden: true, selectedIndex: 'id'},
    {label: '菜单名', key: 'name', type: 'input', value: ''},
    {label: '菜单路径', key: 'path', type: 'input', value: ''},
]

export default function AddBotMenu(props) {
    return <DefaultModalButton
        {...props}
        value="新增"
        formConfig={formConfig}
        updateApi={addBotMenu}
    />
}