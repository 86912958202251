import {Button, Col, Dropdown, Layout, Row, Tabs, Typography} from "antd";
import {useCallback, useEffect, useState} from "react";
import {changeViewByUserAgent, If} from "../../../utils/HtmlUtils";
import {DownOutlined, LeftOutlined} from "@ant-design/icons";
import useJumper from "../../../components/UseJumper";
import LoginCheck from "../../../components/LoginCheck";
import KookControl from "./KookControl";
import LocalControl from "./LocalControl";
import MusicList from "./MusicList";
import MusicSearch from "./MusicSearch";
import {useLocation, useNavigate} from "react-router-dom";
const { Header, Footer, Content } = Layout;

const dropInitList = ['本地播放器', "Kook语音"];
export default function UserMusic({user, onUserLogin}) {
    const {jumpBack} = useJumper()
    const location = useLocation();
    const navigate = useNavigate();

    const [menuKey, setMenuKey] = useState(new URLSearchParams(location.search).get("menuKey") || 'control');
    const [playerSelect, setPlayerSelect] = useState(dropInitList[1]);
    const [playFlag, setPlayFlag] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        urlParams.set('menuKey', menuKey);
        navigate({ search: urlParams.toString() }, { replace: true });
    }, [menuKey, location.search, navigate]);


    const onKookExit = useCallback(() => {
        setPlayerSelect(dropInitList[0]);
    }, []);

    const dropItems = dropInitList.map(key => (
        {key, label: <Typography.Text onClick={() => setPlayerSelect(key)}>{key}</Typography.Text>}
    ))

    const onplay = (listId) => {
        setMenuKey('control');
        setPlayFlag({listId});
    }

    const menuItem = [
        {key: 'control', label: '遥控器', children: <>
                <Row justify="space-evenly" align="middle" >
                    <Dropdown menu={{items: dropItems}}>
                        <Button type="text">
                            {playerSelect}
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                </Row>
                <div style={{padding: 16}}>
                    {If (dropInitList[1] === playerSelect).then(() =>
                        <KookControl playFlag={playFlag} user={user} onKookExit={onKookExit}/>
                    ).else(() => (
                        <LocalControl playFlag={playFlag} user={user}/>
                    ))}
                </div>
            </>},
        {key: 'list', label: '歌单', children: <MusicList user={user} onplay={onplay} onAdd={()=>setMenuKey('import')}/>},
        {key: 'import', label: '导入', children: <MusicSearch user={user}/>},
    ];

    return <LoginCheck user={user} onUserLogin={onUserLogin}>
        {changeViewByUserAgent({
            mobile: <Layout>
                <Header>
                    <Row gutter={[10, 0]}>
                        <Col><LeftOutlined onClick={jumpBack}/></Col>
                        <Col>
                            <Typography.Text>我的歌单</Typography.Text>
                        </Col>
                        <Col offset={4}>
                        </Col>
                    </Row>
                </Header>
                <Content>
                    <Tabs onChange={setMenuKey}
                          activeKey={menuKey}
                          tabPosition="top"
                          items={menuItem}
                    />

                </Content>
                <Footer></Footer>
            </Layout>,
            pc: null,
        })}
    </LoginCheck>
}