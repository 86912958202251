import { useState, useEffect } from 'react';

export default function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const [debounceLoading, setDebounceLoading] = useState(false);

    useEffect(() => {
        setDebounceLoading(true)
        const handler = setTimeout(() => {
            setDebounceLoading(false);
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return [debouncedValue, debounceLoading];
};