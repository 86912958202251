import DefaultTable from "../../../../components/default-table";
import {listMessageRecord} from "../../../../api";

const columnsConfig = [
    {title: 'id', key: 'id', width: 60},
    {title: '消息', key: 'content', width: 140, ellipsis: true},
    {title: '图片', key: 'picList', width: 100, type: 'image'},
    {title: '渠道类型', key: 'sendType', width: 100, type: 'choose', chooseMap: 'sendTypeList'},
    {title: '发送渠道', key: 'senderName', width: 200, type: 'search'},
    {title: '发送者', key: 'userName', width: 200},
    {title: '是否回复', key: 'hasReply', width: 100, type: 'chooseTitle', chooseMap: 'hasReplyList'},
];

const otherResources = {
    hasReplyList: [{text: '是', value: true},{text: '否', value: false}],
}

const buttons = []

export default function MessageRecord() {
    return <DefaultTable
        buttons={buttons}
        columnsConfig={columnsConfig}
        getDataApi={listMessageRecord}
        needResourcesList={['sendTypeList']}
        otherResources={otherResources}
    />
}