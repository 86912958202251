import DefaultTable from "../../../../components/default-table";
import {listRobot} from "../../../../api";
import DownButton from "./DownButton";
import UpButton from "./UpButton";
import AddBotRobot from "./AddBotRobot";
import {If} from "../../../../utils/HtmlUtils";
import {Typography} from "antd";
import DeleteButton from "./DeleteButton";
import EditBotRobot from "./EditBotRobot";
import ChooseBot from "./ChooseBot";

const pushRender = (pushType, record) => {
    return If(pushType === 'ws').then(() => {
        for (const item of otherResources.wsStatusList) {
            if (item.value === record.wsStatus) {
                return item.text;
            }
        }
    }).elseIf(pushType === 'hook').then(() => {
        return <Typography.Paragraph style={{marginBottom: 0}} copyable={{text: record.hookUrl,tooltips: false}}>复制hook地址</Typography.Paragraph>
    }).endIf();
}

const columnsConfig = [
    {title: 'BotId', key: 'id', width: 60},
    {title: 'Bot类型', key: 'type', width: 140, type: 'choose', chooseMap: 'robotTypeList'},
    {title: 'Bot昵称', key: 'name', width: 200},
    {title: '状态', key: 'status', width: 100, type: 'chooseRender', chooseMap: 'statusList'},
    {title: '连接情况', key: 'pushType', width: 100, afterRender: pushRender},
    {title: '操作', type: 'button', width: 100, buttonConfig: (value, record) => record.status === 0? [DownButton,ChooseBot]: [UpButton,ChooseBot]},
    // {key: 'verifyKey', colSpan: 0},
    // {key: 'qq', colSpan: 0}
];

const otherResources = {
    statusList: [{text: '正常', value: 0, tag: 'green'},{text: '停用', value: -1, tag: 'red'}],
    wsStatusList: [{text: '在线', value: 0, tag: 'green'},{text: '离线', value: -1, tag: 'red'}],
    // pushTypeList: [{text: 'Websocket', value: 'ws'}, {text: 'Hook', value: 'hook'}],
}

const buttons = [AddBotRobot, DeleteButton, EditBotRobot]
export default function AppList(props) {
    return <DefaultTable
        globalProps={props}
        buttons={buttons}
        columnsConfig={columnsConfig}
        getDataApi={listRobot}
        needResourcesList={['robotTypeList', 'botTaskResource']}
        otherResources={otherResources}
    />
}