import {useEffect, useState} from "react";
import {For, If, isNotNull} from "../../../utils/HtmlUtils";
import {deleteMusic, listMusic, syncMusic} from "../../../api";
import {Button, Card, Col, Image, Row, Space, Typography} from "antd";
import {CaretRightOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined, SyncOutlined} from "@ant-design/icons";
import './Music.less'

export default function MusicList({user, onplay, onAdd}) {
    const [listList, setListList] = useState([]);
    const [syncLoading, setSyncLoading] = useState(false);

    useEffect(() => {
        if (isNotNull(user?.id)) {
            reqData();
        }
    }, [user?.id])

    const reqData = () => {
        listMusic().then(setListList);
    }

    const syncMusicList = (listId) => {
        setSyncLoading(true);
        const params = {};
        if (isNotNull(listId)) {
            params.listId=listId;
        }
        syncMusic(params).then(() => {
            reqData();
        }).finally(() => {
            setSyncLoading(false)
        })
    }

    const onDelete = listId => {
        deleteMusic(listId).then(reqData);
    }

    const getTypeStr = type => {
        switch (type) {
            case 0: case 1: return "网易云";
            case 2: return "播客";
            case 3: return "B站";
            case 4: return "文件";
            default: return null;
        }
    }

    return <>
        <Space style={{marginLeft: 8}}>
            <Button type="primary" shape="circle" icon={<CaretRightOutlined />} size="large" onClick={onplay.bind(this, null)}></Button>
            <Button type="primary" shape="circle" icon={<PlusOutlined />} size="large" onClick={onAdd}/>
            {If (syncLoading).then(() => (
                <Button type="primary" shape="circle" icon={<LoadingOutlined />} size="large"/>
            )).else(() => (
                <Button type="primary" shape="circle" icon={<SyncOutlined />} size="large" onClick={syncMusicList.bind(this, null)}/>
            ))}
        </Space>
        {For (listList).then(list => <Card size="small" style={{marginTop: 8}} key={list.id}>
            <Row gutter={[10, 0]} justify="space-between" align="middle">
                <Col>
                    <Row>
                        <Space>
                            <Col>
                                <Image width={50} height={50} src={list?.icon} preview={false} className="listIcon"/>
                            </Col>
                            <Col>
                                <Row><Typography.Text ellipsis>{list?.name}</Typography.Text></Row>
                                <Row><Typography.Text type="secondary">{getTypeStr(list?.type)}</Typography.Text></Row>
                            </Col>
                        </Space>
                    </Row>
                </Col>
                <Col>
                    <Space>
                        <Button type="primary" shape="circle" icon={<CaretRightOutlined />} size="large" onClick={onplay.bind(this,list.id)}/>
                        <Button type="primary" shape="circle" icon={<DeleteOutlined />} size="large" onClick={onDelete.bind(this,list.id)}/>
                        {If (syncLoading).then(() => (
                            <Button type="primary" shape="circle" icon={<LoadingOutlined />} size="large"/>
                        )).else(() => (
                            <Button type="primary" shape="circle" icon={<SyncOutlined />} size="large" onClick={syncMusicList.bind(this, list.id)}/>
                        ))}
                    </Space>
                </Col>
            </Row>
        </Card>)}


        {/*<Collapse collapsible="header" ghost items={*/}
        {/*    For (listList).then(list => ({key: list.id, showArrow: false,*/}
        {/*        label: <Row gutter={[10, 0]} justify="space-between" align="middle">*/}
        {/*            <Col>*/}
        {/*                <Space>*/}
        {/*                    <Image width={50} height={50} src={list?.icon} preview={false} className="listIcon"/>*/}
        {/*                    <Typography.Text ellipsis>{list?.name}</Typography.Text>*/}
        {/*                </Space>*/}
        {/*            </Col>*/}
        {/*            <Col>*/}
        {/*                <Button type="primary" shape="circle" icon={<CaretRightOutlined />} size="large" onClick={e=>onplay(e,list.id)}/>*/}
        {/*            </Col>*/}
        {/*        </Row>,*/}
        {/*        children: <>*/}
        {/*            {For (list.musicList).then(music => (*/}
        {/*                <Row>{music.name}</Row>*/}
        {/*            ))}*/}
        {/*        </>*/}
        {/*    }))*/}
        {/*}/>*/}

        {/*{For (listList).then(list => <Card size="small" style={{marginTop: 8}} key={list.id}>*/}
        {/*    <Collapse collapsible="header" ghost items={[{key: '1', showArrow: false, label:*/}
        {/*            <Row gutter={[10, 0]} justify="space-between" align="middle">*/}
        {/*                <Col>*/}
        {/*                    <Space>*/}
        {/*                        <Image width={50} height={50} src={list?.icon} preview={false} className="listIcon"/>*/}
        {/*                        <Typography.Text ellipsis>{list?.name}</Typography.Text>*/}
        {/*                    </Space>*/}
        {/*                </Col>*/}
        {/*                <Col>*/}
        {/*                    <Button type="primary" shape="circle" icon={<CaretRightOutlined />} size="large" onClick={e=>onplay(e,list.id)}/>*/}
        {/*                </Col>*/}
        {/*            </Row>*/}
        {/*    }]}/>*/}
        {/*</Card>)}*/}
    </>
}