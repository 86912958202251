import Control from "./Control";
import {getPlayerData, lastPlayer, startPlayer, stopPlayer} from "../../../api";
import {useEffect, useState} from "react";
import {Spin} from "antd";
import {isNotNull} from "../../../utils/HtmlUtils";

export default function KookControl({playFlag, user, onKookExit}) {
    const [theMusic, setTheMusic] = useState(null);
    const [, setMusicList] = useState(null);
    const [, setPlayerQueue] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLeave, setIsLeave] = useState(false);
    const [lastLoading, setLastLoading] = useState(false);
    const [stopLoading, setStopLoading] = useState(false);
    const [initLoading, setInitLoading] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            getPlayerData().then(data => {
                setInitLoading(false);
                setIsLeave(false);

                setTheMusic(data.theMusic);
                setPlayerQueue(data.playerQueue);
                setMusicList(data.musicList);
                setIsPlaying(data.playing);
            }).catch(() => {
                setIsLeave(true);
            });
        }, 1000);

        return () => {
            clearInterval(intervalId);
        }
    }, [user?.id])

    useEffect(() => {
        if (isLeave && initLoading) {
            onKookExit();
        }
    })

    useEffect(() => {
        setLastLoading(false);
    }, [theMusic])

    useEffect(() => {
        setStopLoading(false);
    }, [isPlaying])

    useEffect(() => {
        if (isNotNull(playFlag)) {
            onStart(playFlag.listId);
        }
    }, [playFlag])

    const onStop = () => {
        setStopLoading(true);
        stopPlayer().then().catch(() => {
            setStopLoading(false);
        })
    }

    const onStart = (listId) => {
        setStopLoading(true);
        startPlayer({listId:listId}).then().catch(() => {
            setStopLoading(false);
        })
    }

    const onLast = () => {
        setLastLoading(true);
        lastPlayer().then();
    }

    const onShowQueue = () => {

    }

    return <Spin spinning={lastLoading || stopLoading || initLoading} style={{padding: 8}}>
        <Control theMusic={theMusic} isPlaying={isPlaying} onStart={onStart} onStop={onStop} onLast={onLast} onShowQueue={onShowQueue}/>
    </Spin>
}