import {converseToForm} from "../../../../components/default-modal-button";
import {useEffect, useState} from "react";
import {Button, Card, Spin} from "antd";
import {listBotConfig, updateConfig} from "../../../../api";
import {assignNewObject, For} from "../../../../utils/HtmlUtils";

const formConfig = [
    {label: "temperature", key: "chat-gpt.temperature", type: 'input'},
    {label: "temperature", key: "chat-gpt.top_p", type: 'input'},
    {label: "AI语境", key: "chat-gpt.system", type: 'textArea', autoSize: true},
]
const resources = {}
const col = 2;
export default function BotConfig(props) {
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        updateData();
    }, []);

    function updateData() {
        setLoading(true);
        listBotConfig({}).then(list => {
            const newParams = For(list).assignReduce(config => [config.key, config.value]);
            setParams(newParams);
        }).finally(() => setLoading(false))
    }

    function submit() {
        const configList = formConfig.map(({key}) => ({key, value: params[key]}));
        updateConfig({configList}).then(updateData);
    }

    function handleChange(key, value) {
        console.log(key, value)
        setParams(assignNewObject(params, key, value));
    }

    return <Card>
        <Spin spinning={loading}>
            <Button type="primary" onClick={updateData}>刷新</Button>
            <Button type="primary" onClick={submit} style={{ marginLeft: 8 }}>提交</Button>
            {converseToForm({formConfig, params, resources, col, size:'default', onChange:handleChange})}
        </Spin>
    </Card>
}