import DefaultTable from "../../../../components/default-table";
import {getResources, listBotMenu} from "../../../../api";
import AddBotMenu from "./AddBotMenu";
import DeleteBotMenu from "./DeleteBotMenu";
import {isNotNull} from "../../../../utils/HtmlUtils";
import {useEffect, useState} from "react";
import UpdateRoleMapping from "./UpdateRoleMapping";



const buttons = [AddBotMenu, DeleteBotMenu]
export default function MenuManager() {
    const [columnsConfig, setColumnsConfig] = useState([]);
    useEffect(()=> {
        getResources({needResourcesList: ['roleList']}).then(resources => {
            if (isNotNull(resources.roleList)) {
                const columnsConfig = [
                    {title: '菜单名', key: 'name', width: 140},
                    {title: '菜单路径', key: 'path', width: 200}
                ];
                columnsConfig.push(...resources.roleList.map(role => ({
                    key: role.value,
                    title: role.text,
                    width: 90,
                    type: 'switch',
                    switch: UpdateRoleMapping,
                })))
                setColumnsConfig(columnsConfig)
            }
        })
    }, [])
    return <DefaultTable
        buttons={buttons}
        columnsConfig={columnsConfig}
        getDataApi={listBotMenu}
    />
}