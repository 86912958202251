import DefaultModalButton from "../../../../components/default-modal-button";
import {upBot} from "../../../../api";

const formConfig = [
    {key: 'id', hidden: true},
]

export default function UpButton(props) {
    return <DefaultModalButton
        {...props}
        value="上线"
        formConfig={formConfig}
        updateApi={upBot}
        buttonType="primary"
    />
}