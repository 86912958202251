import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Row, Table} from "antd";
import {
    assignNewObject,
    convertToPrams,
    defaultRowKey,
    emptyObject,
    For,
    If,
    isNotEmpty,
    isNull,
    suppleTableColumn
} from "../../utils/HtmlUtils";
import './index.css'
import {getResources} from "../../api";

const initDefaultPagination={pageSize: 20, current: 1}
const initDefaultFilters={}
const initDefaultSorter={}
export default function DefaultTable(props) {
    const {
        defaultSorter=initDefaultSorter,
        defaultPagination=initDefaultPagination,
        defaultFilters=initDefaultFilters,
        buttons=[],
        getDataApi,
        columnsConfig,
        rowKey=defaultRowKey,
        needResourcesList=[],
        otherResources=emptyObject,
        globalProps={},
        isShowRowSelection=true,
        isMultipleSelect=false,
    } = props;


    const [data, setData] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState(defaultFilters);
    const [pagination, setPagination] = useState(defaultPagination);
    const [sorter, setSorter] = useState(defaultSorter);
    const [resources, setResources] = useState({});

    const {total,pageSize,current,list=[]} = data;
    const selectedRowKeys = selectedRows.map(rowKey);
    const selectRowLength = selectedRows.length;
    const rowLength = list.length;

    const updateData = useCallback(() => {
        const params = convertToPrams(pagination, filters, sorter)
        console.info("req data ", params)
        setLoading(true);
        getDataApi(params).then(data => {
            setData(data);
            setSelectedRows([])
        }).finally(() => setLoading(false))
    }, [getDataApi, pagination, filters, sorter]);

    useEffect(() => {
        updateData();
    }, [updateData])

    useEffect(() => {
        if (isNotEmpty(needResourcesList)) {
            getResources({needResourcesList}).then(resources => {
                setResources(Object.assign(resources, otherResources))
            })
        }
    }, [needResourcesList, otherResources])

    // 表格行选中事件
    const handleRowSelect = (record) => {
        const newKey = rowKey(record);
        // 获取已选中的行
        let newSelectedRows;//selectedRows.slice();
        // 判断是否已选中该行
        let index = selectedRowKeys.indexOf(newKey);
        if (index === -1) {
            // 未选中，则添加到已选中行列表中
            // 如果是单选就直接替换
            if (isMultipleSelect) {
                newSelectedRows = [...selectedRows, record];
            } else {
                newSelectedRows = [record];
            }
        } else {
            // 已选中，则从已选中行列表中移除
            newSelectedRows = selectedRows.filter((_, i) => i !== index);
        }
        // 更新选中行状态
        setSelectedRows(newSelectedRows);
    };

    const onRow = (record) => {
        return {
            onClick: () => {
                handleRowSelect(record);
            },
        };
    }

    const onChange = (newPagination, newFilters, newSorter) => {
        console.info(pagination, filters, sorter);
        setFilters(Object.assign({}, filters, newFilters));
        setPagination(Object.assign({}, pagination, newPagination));
        setSorter(Object.assign({}, sorter, newSorter));
    }

    const onUpdateSuccess = () => {
        updateData();
    }

    const setFilter = (key, value) => {
        setFilters(assignNewObject(filters, key, isNull(value)? null: [value]));
        // setPagination(defaultPagination);
    }

    const clearFilter = () => {
        setFilters(defaultFilters);
        setPagination(defaultPagination);
        setSorter(defaultSorter);
    }

    const handleSelectAll = () => {
        const selectedRows = [...list];
        setSelectedRows(selectedRows)
    }

    const handleNotSelectAll = () => {
        setSelectedRows([]);
    }

    const columns = columnsConfig.map(columnConfig => {
        return suppleTableColumn(columnConfig, {filters, sorter, setFilter, resources, onUpdateSuccess, globalProps, loading});
    })

    const paginationProp = {
        pageSize: pageSize,
        total: total,
        current: current,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ['5', '10', '20', '50', '100'],
        showSizeChanger: true,
        showQuickJumper: true,
    }
    const rowSelection = {
        type: isMultipleSelect? 'checkbox': 'radio',
        selectedRowKeys: selectedRowKeys,
        preserveSelectedRowKeys: false,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    }
    const scroll = {
        x: 0,
        y: window.innerHeight - 300,
    }
    return (
        <Card tabIndex="-1" size="small">
            <Row type="flex" className="margin-bottom-8">
                <Button type="primary" onClick={updateData}>刷新</Button>
                <Button type="primary" onClick={clearFilter} className="margin-left-8">重置</Button>
                {If (isMultipleSelect && (selectRowLength < rowLength || rowLength === 0)).then(() => (
                    <Button type="primary" onClick={handleSelectAll} className="margin-left-8" disabled={loading || rowLength === 0}>全选</Button>
                )).elseIf(isMultipleSelect && selectRowLength === rowLength).then(() => (
                    <Button type="primary" onClick={handleNotSelectAll} className="margin-left-8" disabled={loading || rowLength === 0}>取消全选</Button>
                )).endIf()}
                {For(buttons).then((Button, index) => (
                    <Button key={index} selectedRows={selectedRows} onSuccess={onUpdateSuccess} resources={resources} globalProps={globalProps} filters={filters} setFilter={setFilter}/>
                ))}
            </Row>
            <Table
                // 是否展示外边框和列边框
                bordered
                // 	表格列的配置描述
                columns={columns}
                // 数据数组
                dataSource={list}
                // 页面是否加载中
                loading={loading}
                // 分页器，参考配置项或 pagination 文档，设为 false 时不展示和进行分页
                pagination={paginationProp}
                // 表格行 key 的取值，可以是字符串或一个函数
                rowKey={rowKey}
                // 表格行是否可选择，配置项
                rowSelection={isShowRowSelection && rowSelection}
                // 表格是否可滚动，也可以指定滚动区域的宽、高，配置项
                scroll={scroll}
                // 是否显示表头
                showHeader={true}
                // 表头是否显示下一次排序的 tooltip 提示。当参数类型为对象时，将被设置为 Tooltip 的属性
                showSorterTooltip={true}
                // 表格大小
                size='small'
                // sortDirections
                sortDirections={['ascend', 'descend']}
                // 设置粘性头部和滚动条
                sticky={false}
                // 分页、排序、筛选变化时触发
                onChange={onChange}
                // 设置行属性
                onRow={onRow}
            />
        </Card>
    )
}

