import {Input} from "antd";

const TextInput = (props) => {

    function onChange(event) {
        return props.onChange(event.target.value);
    }

    return (
        <Input {...props} onChange={onChange}/>
    )
}

export default TextInput;