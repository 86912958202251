import {Button} from "antd";
import {useNavigate} from "react-router-dom";

export default function ChooseBot(props) {
    const navigate = useNavigate();
    const {onBotChange} = props.globalProps;
    const handleClick = ()=> {
        const bot = props.selectedRows[0];
        onBotChange(bot)
        navigate(`/admin/bot?bot=${bot.id}`)
    }
    return <Button className="margin-left-8" type="primary" onClick={handleClick}>查看</Button>
}