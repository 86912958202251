import {
    AppstoreOutlined,
    FastBackwardOutlined,
    MenuOutlined,
    StepBackwardOutlined,
    StepForwardOutlined
} from "@ant-design/icons";
import MenuManager from "../app/admin/super/menu-manager/MenuManager";
import AppList from "../app/admin/app/app-list/AppList";
import MessageRecord from "../app/admin/app/message-record/MessageRecord";
import RoleManager from "../app/admin/super/role/RoleManager";
import BotSenderManager from "../app/admin/app/sender/BotSenderManager";
import RandomTalk from "../app/admin/app/random-talk/RandomTalk";
import BotConfig from "../app/admin/app/config/BotConfig";
import BotUserManager from "../app/admin/app/user/BotUserManager";

export function suppleMenuIcon(menuList) {
    menuList?.forEach(menu => {
        menu.icon = iconConfig[menu.key];
        suppleMenuIcon(menu.children);
    })
}

const iconConfig = {
    "super/menuManager": <MenuOutlined />,
    "super/test1": <StepBackwardOutlined />,
    "super/test2": <StepForwardOutlined />,
    "super/test3": <FastBackwardOutlined />,
    "app/appList": <AppstoreOutlined />,
}

export const componentConfig = {
    "super/menuManager": MenuManager,
    "super/role": RoleManager,
    "app/appList": AppList,
    "app/messageRecord": MessageRecord,
    "app/sender": BotSenderManager,
    "app/randomTalk": RandomTalk,
    "app/config": BotConfig,
    "app/user": BotUserManager,
}