import useJumper from "./UseJumper";
import {useEffect, useState} from "react";
import {isNull} from "../utils/HtmlUtils";
import {isLogin} from "../api";
import {useLocation} from "react-router-dom";
import {Spin} from "antd";

export default function LoginCheck({user, onUserLogin, children}) {
    const location = useLocation();
    const {jumpToLogin} = useJumper();
    const path = location.pathname;

    const [loading, setLoading] = useState(false);

    // 加载菜单
    useEffect(() => {
        if (isNull(user)) {
            setLoading(true)
            isLogin().then(user => {
                onUserLogin(user);
                if (isNull(user)) {
                    jumpToLogin(path)
                }
            }).catch(() => {
                jumpToLogin(path)
            }).finally(() => setLoading(false));
        }
    }, [user, jumpToLogin, onUserLogin, path]);

    return <Spin spinning={loading}>
        {children}
    </Spin>
}