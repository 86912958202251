import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./utils/prototypeUtil"
import MyApp from './app/MyApp';
import {App, ConfigProvider} from 'antd';
import { BrowserRouter } from "react-router-dom";

// ReactDOM.render(
//   // <React.StrictMode>
//     <ConfigProvider theme={{token: {colorPrimary: '#FFAA33',}}}>
//         <App />
//     </ConfigProvider>,
//   // </React.StrictMode>,
//   document.getElementById('root')
// );

const theme = {
    token: {
        colorPrimary: '#FFAA33',
    },
    components: {
        Layout: {
            headerBg: '#FFFFFF',
            headerPadding: '0px 20px',
        },
        Tabs: {
            horizontalMargin: '8px 0 8px 0'
        }
    },
}
ReactDOM.createRoot(document.getElementById("root")).render(
    // <React.StrictMode>
        <ConfigProvider theme={theme}>
            <App>
                <BrowserRouter>
                    <MyApp/>
                </BrowserRouter>
            </App>
        </ConfigProvider>
    // </React.StrictMode>
);
