import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getTweetByExternalId} from "../../api";
import {For, If, isNotBlank, isNotNull} from "../../utils/HtmlUtils";
import './Dynamic.css';

export default function Dynamic() {
    const { type, externalId } = useParams();
    const [dynamic, setDynamic] = useState({user:{face: '', nick: '', picList: '', sendTime: ''
            , shareImage: '', shareTitle: '', shareText: '', shareUrl:''}});

    useEffect(() => {
        getTweetByExternalId(type, externalId).then(setDynamic);
    }, [type, externalId])

    function jumpToSource(dynamicData) {
        window.open(dynamicData.shareUrl, "_blank")
    }

    return <div className="card">
        {If(isNotBlank(dynamic?.user?.nick)).then(() => <>
            <span className="span-item" style={{width: 50}}><img className="img-face" src={dynamic.user.face}  alt="face"/></span>
            <span className="span-item" style={{width: 500}}>
                <div>
                    <strong>{dynamic.user.nick + (dynamic.user.nick === dynamic.user.username ? "" : "@" + dynamic.user.username)}</strong>
                    <span style={{color: "gray"}}>{" " + new Date(dynamic.sendTime).format("MM月dd日 hh时mm分")}</span>
                </div>
                <div>
                    {For(dynamic?.text?.split("\n")).then((line, index) => <div key={index}><span>{line}</span></div>)}
                    {For(dynamic?.picList?.split(",") || []).then((pic, index) => (
                        <img src={pic} alt="pic" key={index} style={{width: "100%"}}/>
                    ))}
                    {If(isNotBlank(dynamic.shareTitle)).then(() =>
                        <div className="card-share" onClick={() => jumpToSource(dynamic)}>
                            <span className="span-item" style={{fontSize: 0, width: 180}}>
                                <img className="img-share-card" src={dynamic.shareImage} alt="shareImage"/>
                            </span>
                            <span className="span-item" style={{width: 300}}><div style={{padding: 5}}>
                                <div className="ellipsis">{dynamic.shareTitle}</div>
                                <span className="card-text">{dynamic.shareText}</span>
                            </div></span>
                        </div>
                    ).endIf()}
                </div>
            </span>
            {If(isNotNull(dynamic.quote)).then(() =>
                <div className="card-sub">
                    <div style={{padding: 7}}>
                        <div style={{height: "2em"}}>
                            <span className="span-item"><img className="img-face-small" src={dynamic.quote.user.face}  alt="face-small"/></span>
                            <span className="span-item">
                                <strong>{dynamic.quote.user.nick + (dynamic.quote.user.nick === dynamic.quote.user.username ? "" : "@" + dynamic.quote.user.username)}</strong>
                                <span style={{color: "gray", marginLeft: 8}}>{new Date(dynamic.quote.sendTime).format("MM月dd日 hh时mm分")}</span>
                            </span>
                        </div>
                        <div>
                            {For(dynamic?.quote?.text?.split("\n")).then((line, index) => <div key={index}><span>{line}</span></div>)}
                            {For(dynamic?.quote?.picList?.split(",") || []).then((pic, index) => (
                                <img src={pic} alt="pic" key={index} style={{width: "100%"}}/>
                            ))}
                        </div>
                        {If(isNotBlank(dynamic.quote.shareTitle)).then(() =>
                            <div className="card-sub-share" onClick={() => jumpToSource(dynamic.quote)}>
                                <span className="span-item" style={{fontSize: 0, width: 180}}>
                                    <img className="img-share-card" src={dynamic.quote.shareImage} alt="shareImage"/>
                                </span>
                                <span className="span-item" style={{width: 300}}><div style={{padding: 5}}>
                                    <div className="ellipsis">{dynamic.quote.shareTitle}</div>
                                    <span className="card-text">{dynamic.quote.shareText}</span>
                                </div></span>
                            </div>
                        ).endIf()}
                    </div>
                </div>
            ).endIf()}
        </>).endIf()}
    </div>
}