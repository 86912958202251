import DefaultTable from "../../../../components/default-table";
import {getResources, listRole} from "../../../../api";
import {useEffect, useState} from "react";
import {isNotNull} from "../../../../utils/HtmlUtils";

const otherResources = {}
const buttons = [];
export default function RoleManager(props) {
    const [columnsConfig, setColumnsConfig] = useState([]);
    useEffect(()=> {
        getResources(['roleList']).then(resources => {
            if (isNotNull(resources.roleList)) {
                setColumnsConfig(resources.roleList.map(role => ({
                    key: role.value,
                    title: role.text,
                })))
            }
        })
    }, [])
    return <DefaultTable
        globalProps={props}
        buttons={buttons}
        columnsConfig={columnsConfig}
        getDataApi={listRole}
        needResourcesList={[]}
        otherResources={otherResources}
    />
}