import React from "react";
import {Input, Popover, Typography, message, Select, Tag, Switch} from "antd";
import CacheInput from "../components/cache-input";
const { Paragraph } = Typography;

export const isNull = (obj, or) => {
    if (or === undefined) {
        return obj === undefined || obj === null;
    }else {
        return obj === undefined || obj === null? or: obj;
    }
};
export const isNotNull = obj => !isNull(obj);
export const isEmpty = list => list === undefined || list === null || list.length === 0;
export const isNotEmpty = list =>  !isEmpty(list);
export const isEmptyObject = obj => isNull(obj) || Object.keys(obj).length === 0
export const isNotEmptyObject = obj => !isEmptyObject(obj)
export const isBlank = (string, or) => or === undefined
    ? string === undefined || string === null || string.length === 0
    : string === undefined || string === null || string.length === 0? or: string;
export const isNotBlank = (string, or) => or === undefined? !isBlank(string): !isBlank(string)? or: string

export const getColumnChooseProps = (filteredInfo, key, map, isFilter = true) => ({
    filterMultiple: false,
    filteredValue: isNull(filteredInfo[key])? null : [filteredInfo[key]],//根据filteredInfo决定筛选参数
    filters: isFilter? map: undefined,
    render: key => {
        for (const obj of (map || [])) {
            if (obj.value === key) {
                return obj.text;
            }
        }
    }
})

export const getColumnOrderProps = (sorter, key) => ({
    sorter: true,
    sortDirections: ['descend', 'ascend', ],
    sortOrder: sorter.field === key? (sorter.order || null) : null,
})

export function getParagraph(text, smallText, index) {
    return (
        <Popover placement="right" content={text} key={index}>
            <Paragraph ellipsis style={{marginBottom: "0em"}}>{smallText || text}</Paragraph>
        </Popover>
    )
}

export const InputTitle = (props) => {
    const {title, filteredInfo, index, style, onChanged, onChange} = props;
    return <div>
        <Input size="small" placeholder={title} value={filteredInfo[index]} style={style} allowClear
               onPaste={e=>{
                   e.preventDefault();
                   const newFilteredInfo = defineProperty(filteredInfo, index, e.clipboardData.getData('Text'));
                   onChange(newFilteredInfo)
                   onChanged(newFilteredInfo)
               }}
               onPressEnter={e=>onChanged(defineProperty(filteredInfo, index, e.target.value))}
               onChange={e=> {
                   const newFilteredInfo = defineProperty(filteredInfo, index, e.target.value);
                   onChange(newFilteredInfo)
                   if (isBlank(e.target.value)) {
                       onChanged(newFilteredInfo)
                   }
               }}
        />
    </div>
}
export const Img = (props) => {
    return <img src={props.loading?"/loading.png":props.src} style={props.style} alt={props.alt} onClick={props.onClick}/>
}

export const getSmallImg = (loading, hrefConverse, onClick) => {
    const converseUrl = src => isEmpty(src)? "/loading.png": src;
    return {
        width: 60,
        className: "no-padding",
        render: (src, row={}) => getParagraph(
            <Img loading={loading} style={{height: '400px'}} alt='-' src={converseUrl(src)} onClick={onClick}/>,
            If(isNull(hrefConverse)).then(() =>
                <Img loading={loading} style={{height: '37px', maxWidth: '60px'}} alt='-' src={converseUrl(src)} onClick={onClick}/>
            ).else(() =>
                <a href={hrefConverse(row)} target="_Blank" rel="noopener noreferrer">
                    <Img loading={loading} style={{ height: '37px', maxWidth: '60px' }} src={converseUrl(src)} alt="-"/>
                </a>
            )
        )
    }
}

export const InputSelect = (props) => (
    <Select value={isNull(props.value)? props.value: props.value.toString()} onChange={props.onChange} onSelect={props.onSelect} showSearch autoClearSearchValue allowClear dropdownMatchSelectWidth optionFilterProp="children" filterOption={inputSelectFilterOption} placeholder={props.placeholder} size={props.size}>
        {For(props.resource).then((item, index) => (
            <Select.Option key={index} value={isNull(item.value)? item.value: item.value.toString()}>{isNull(item.text)? item.text: item.text.toString()}</Select.Option>
        ))}
    </Select>
)
export const MultipleInputSelect = (props) => (
    <Select mode="multiple" value={isNull(props.value)? []: props.value.split(",")} onChange={value => props.onChange(Array.isArray(value)? value.join(','): null)} onSelect={props.onSelect} showSearch autoClearSearchValue allowClear dropdownMatchSelectWidth optionFilterProp="children" filterOption={inputSelectFilterOption} placeholder={props.placeholder} size={props.size}>
        {For(props.resource).then((item, index) => (
            <Select.Option key={index} value={isNull(item.value)? item.value: item.value.toString()}>{isNull(item.text)? item.text: item.text.toString()}</Select.Option>
        ))}
    </Select>
)

export const inputSelectFilterOption = (input, option) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export const getInputTitle = (title, index, filteredInfo, onChanged, onChange, style) => ({
    title: <InputTitle
        {...{title, index, filteredInfo, onChanged, onChange, style}}
    />,
    filteredValue: isNull(filteredInfo[index])? null : [filteredInfo[index]],
    className: "no-padding",
})

//注意，此工具会会渲染每个条件的全部元素，注意性能和空值处理
const runFunction = func => typeof func === "function"? func(): func;
export function If(boolean) {
    return new IfClass(boolean);
}

export class IfClass {
    constructor(boolean) {
        this.boolean = boolean;
        this.result = null;
    }

    if (boolean) {
        this.boolean = boolean;
        return this;
    }

    then (result) {
        if (this.boolean && this.result == null) {
            this.result = result;
        }
        return this;
    }

    elseIf (boolean) {
        this.boolean = boolean;
        return this;
    }

    else (result) {
        if (this.result != null) {
            return runFunction(this.result);
        }else {
            return runFunction(result);
        }
    }

    endIf () {
        return runFunction(this.result);
    }
}

export const For = object => {
    if (Array.isArray(object)) {
        return new ForArray(object);
    }
    if (typeof object === 'object' && isNotNull(object)) {
        return new ForObject(object);
    }
    return new ForArray([]);
}

class ForArray {
    constructor(list) {
        this.list = list;
    }
    then(mapFun) {
        return this.list.map(mapFun)
    }
    if(filterFun) {
        this.list = this.list.filter(filterFun)
        return this;
    }
    flatThen(mapFun) {
        return this.list.flatMap(mapFun);
    }
    assignReduce(entryFun) {
        const tmp = {};
        for (const item of this.list) {
            const [key, value] = entryFun(item);
            tmp[key] = value;
        }
        return tmp;
    }
}

class ForObject {
    constructor(object) {
        this.object = object;
    }
    then(mapFun) {
        const tmp = {};
        for (const key of Object.keys(this.object)) {
            tmp[key] = mapFun(key, this.object[key]);
        }
        return tmp;
    }
    if(filterFun) {
        const tmp = {};
        for (const key of Object.keys(this.object)) {
            if (filterFun(key, this.object[key])) {
                tmp[key] = this.object[key];
            }
        }
        this.object = tmp;
        return this;
    }
    every(fun) {
        let answer = true
        for (const key of Object.keys(this.object)) {
            answer &= fun(key, this.object[key]);
        }
        return answer;
    }
}


export const convertToPrams = (pagination = {}, filters = {}, sorter = {}) => {
    filters = For(filters).if((key,value) => isNotNull(value))
        .then((key, value) => value[0]);
    const params = Object.assign({}, filters, pagination);
    if (isNotEmptyObject(sorter)) {
        params.sorter = toLine(sorter.field)
        params.sorted = sorter.order === 'descend' ? 'desc' : 'asc';
    }
    // return {query: params};
    return params
}

export const emptyFunc = () => {}
export const selfFunc = a => a
export const emptyObject = {}
export const nullFunc = () => null;

export const compose = (firstFun = selfFunc, secondFun = selfFunc) => {
    return (...props) => secondFun(firstFun(...props));
}

function currying(fn) {
    return function _currying(...args) {
        if (args.length >= fn.length) {
            return fn(...args)
        }
        return function (...args2) {
            return _currying(...args, ...args2)
        }
    }
}

export const defineProperty = (obj, key, value) => {
    return Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        value
    })
}

export const assignNewObject = (obj, key, value) => {
    return Object.assign({}, obj, defineProperty({}, key, value))
}

export const toLine = (name) => {
    return name.replace(/([A-Z])/g,"_$1").toLowerCase();
}

export const splitToList = (key, text) => {
    if (isNotNull(text)) {
        return text.split(key).map(text => <p key={text} style={{marginBottom: "0em"}}>{text}</p>);
    }
}

export const splitToListRender = currying(splitToList)

export const prefixRender = currying((unit, text) => {
    if (isNotNull(text)) {
        return unit + text
    }
})

export const suffixRender = currying((unit, text) => {
    if (isNotNull(text)) {
        return text + unit;
    }
})

export const checkResp = data => {
    if (isNull(data)) {
        data = {};
    }
    if (isNull(data.success)) {
        data.success = true;
    }
    if(!data.success) {
        message.error(isBlank(data.message, '失败'));
        return data;
    } else {
        return data;
    }
}

export const checkRespForData = data => {
    if (checkResp(data)) {
        return data.data;
    }else {
        return data;
    }
}

export const defaultRowKey = record => record.id;

export const defaultScroll = {x:1,y: true}

export const dateFormat = date => {
    if (isNull(date)) {
        return null;
    }
    if (date < 60) {
        return date + '秒';
    }
    return `${Math.floor(date / 60)}分${date % 60}秒`
}

export const toString = (obj) => {
    return isNull(obj)? obj: obj.toString();
}

export const ignoreError = ignore => {}

export const copyableRender = (value, copyText) => (<Paragraph copyable={isNotNull(copyText)? {text: copyText}: true} style={{marginBottom: 0}}>{value}</Paragraph>)


const newChooseColumn = (column, columnConfig, that) => {
    newChooseTitleColumn(column, columnConfig, that);
    newChooseRenderColumn(column, columnConfig, that);
}
const newChooseTitleColumn = (column, columnConfig, that) => {
    const {resources, filters} = that;
    const resource = resources[columnConfig.chooseMap];

    column.filteredValue = filters[columnConfig.key] || null;
    column.filterMultiple = false;
    column.filters = resource;
}
const newChooseRenderColumn = (column, columnConfig, that) => {
    const {resources} = that;
    const resource = resources[columnConfig.chooseMap];

    column.render = key => {
        if (isNotEmpty(resource) && isNotNull(key)) {
            for (const item of resource) {
                if (item.value.toString() === key.toString()) {
                    if (isNotNull(item.tag)) {
                        return newTag(item.text, item.tag);
                    }else if (item.renderHidden === true) {
                        return '';
                    }else {
                        return item.text;
                    }
                }
            }
        }
    }
}
const newTag = (text, tag) => {
    if (tag === 'no') {
        return <Tag>{text}</Tag>
    }
    return <Tag color={tag}>{text}</Tag>
}
const newButtonColumn = (column, columnConfig, that) => {
    const {onUpdateSuccess, resources, globalProps} = that;
    column.className = "no-padding";
    if (Array.isArray(columnConfig.buttonConfig)) {
        column.render = (value, record) => (columnConfig.buttonConfig || []).map((Button, index) => (
            <Button key={index} selectedRows={[record]} onSuccess={onUpdateSuccess} resources={resources} globalProps={globalProps}/>
        ));
    } else {
        column.render = (value, record) => isNull((columnConfig.buttonConfig || emptyFunc)(value, record), []).map((Button, index) => (
            <Button key={index} selectedRows={[record]} onSuccess={onUpdateSuccess} resources={resources} globalProps={globalProps}/>
        ));
    }
}
const newImage = (column, columnConfig, that) => {
    const converseUrl = src => isEmpty(src)? "/loading.png": src;
    const {loading} = that;

    column.className = "no-padding";
    column.width = column.width || 60;
    column.render = (data, row) => {
        let srcList;
        if (Array.isArray(data)) {
            srcList = data;
        } else {
            srcList = [data];
        }
        return srcList.map((src, index) => getParagraph(
            <Img loading={loading} style={{width: '80px', height: '80px'}} alt='-' src={converseUrl(src, 80)}/>,
            If(isNull(columnConfig.href)).then(() =>
                <Img loading={loading} style={{height: '37px', maxWidth: '60px'}} alt='-' src={converseUrl(src, 60)}/>
            ).else(() =>
                <a href={columnConfig.href(src, row)} target="_Blank" rel="noopener noreferrer">
                    <Img loading={loading} style={{ height: '37px', maxWidth: '60px' }} src={converseUrl(src, 60)} alt="-"/>
                </a>
            ),
            index
        ))
    }
}
const newSearchColumn = (column, columnConfig, that) => {
    const {filters, setFilter} = that
    column.className = "no-padding";
    column.title = (
        <CacheInput
            placeholder={columnConfig.title}
            value={isNull(filters[columnConfig.key], [])[0]}
            width={columnConfig.width}
            onChange={value=>setFilter(columnConfig.key, value)}
        />
    );
}
const newSwitchColumn = (column, columnsConfig, that) => {
    const {onUpdateSuccess} = that
    column.render = (value, record) => {
        const TheSwitch = columnsConfig.switch || Switch;
        return <TheSwitch selectedRows={[record]} columnsConfig={columnsConfig} onSuccess={onUpdateSuccess}/>
    }
}
const newSorterColumn = (column,  columnConfig, that) => {
    const {sorter} = that;

    column.sorter = true;
    column.sortOrder = sorter.field === columnConfig.key? (sorter.order || null) : null;
}

export const suppleTableColumn = (columnConfig, that) => {
    const column = {};
    column.title = columnConfig.title;
    column.key = columnConfig.key;
    column.width = columnConfig.width;
    column.dataIndex = columnConfig.dataIndex || columnConfig.key;
    column.align = columnConfig.align || 'center';
    column.ellipsis = columnConfig.theEllipsis;

    if (columnConfig.type === 'choose') {
        newChooseColumn(column, columnConfig, that);
    } else if (columnConfig.type === 'chooseRender') {
        newChooseRenderColumn(column, columnConfig, that);
    } else if (columnConfig.type === 'chooseTitle') {
        newChooseTitleColumn(column, columnConfig, that);
    } else if (columnConfig.type === 'button') {
        newButtonColumn(column, columnConfig, that);
    } else if (columnConfig.type === 'image') {
        newImage(column, columnConfig, that);
    } else if (columnConfig.type === 'search') {
        newSearchColumn(column, columnConfig, that);
    } else if (columnConfig.type === 'switch') {
        newSwitchColumn(column, columnConfig, that);
    }else if (columnConfig.type === 'sorter') {
        newSorterColumn(column, columnConfig, that);
    }

    // else if (columnConfig.type === 'chooseInput') {
    //     this.newChooseInput(column, columnConfig);
    // }else if (columnConfig.type === 'button') {
    //     this.newButtonColumn(column, columnConfig);
    // }else if (columnConfig.type === 'custom') {
    //     this.newCustomColumn(column, columnConfig);
    // }
    //
    if (isNotNull(columnConfig.ellipsis)) {
        if (columnConfig.ellipsis === true) {
            column.render = compose(column.render, getParagraph)
        }else if (typeof columnConfig.ellipsis === 'string') {
            const oldRender = column.render;
            column.render = compose(oldRender, text => getParagraph(
                splitToList(columnConfig.ellipsis, text), text)
            )
        }
    }else if (isNotNull(columnConfig.afterRender)) {
        const oldRender = column.render || selfFunc;
        column.render = (text, record) => columnConfig.afterRender(oldRender(text, record), record)
    }
    // else if (isNotNull(columnConfig.href)) {
    //     const oldRender = column.render || selfFunc;
    //     const afterRender = (value, record) => {
    //         const a = <a href={columnConfig.href(value, record)} target="_Blank" rel="noopener noreferrer">{value}</a>
    //         return isNotNull(columnConfig.copyable) ? copyableRender(a, value) : a;
    //     }
    //     column.render = (text, record) => afterRender(oldRender(text, record), record)
    // }else if (isNotNull(columnConfig.copyable)) {
    //     column.render = compose(column.render, copyableRender)
    // }

    return column;
}

export const os = function() {
    let ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        // isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
    return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid : isAndroid,
        isPc : isPc
    };
}();
export const changeViewByUserAgent = ({pc, mobile}) => {
    // const viewportHeight = window.innerHeight;
    // const viewportWidth = window.innerWidth;
    //
    // const widthByVh = 0.6 * viewportHeight;
    // const widthByVw = viewportWidth;
    //
    // const finalWidth = Math.min(widthByVh, widthByVw);
    //
    // mobile = <div style={{width: finalWidth}}>
    //     {mobile}
    // </div>
    if (isNull(pc) && isNotNull(mobile)) {
        pc = <div style={{width: '56vh'}}>
            {mobile}
        </div>
    }
    if(os.isAndroid || os.isPhone) {
        return isNull(mobile, pc);
    } else {
        return isNull(pc, mobile);
    }
}

export const log = {
    info: (...props) => {
        console.log(...props);
        message.info(...props);
    }
}