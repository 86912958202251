import DefaultModalButton from "../../../../components/default-modal-button";
import {bindUser} from "../../../../api";

const formConfig = [
    {key: 'id', hidden: true},
    {label: 'QQ号', key: 'qq', type: 'input'},
]

export default function BindUser(props) {
    return <DefaultModalButton
        {...props}
        value="合体"
        formConfig={formConfig}
        disabled={props.selectedRows.length !== 1}
        updateApi={bindUser}
    />
}