import {Avatar, Button, ConfigProvider, Popover, Row, Slider, Spin, Switch, Typography} from "antd";
import {
    BarsOutlined,
    CaretRightOutlined, LoadingOutlined,
    PauseOutlined, SoundOutlined,
    StepBackwardOutlined,
    StepForwardOutlined
} from "@ant-design/icons";
import {If, isNotNull, isNull} from "../../../utils/HtmlUtils";
import UpdateSlider from "../../../components/UpdateSlider";

export default function Control({theMusic, isPlaying, onStart, onStop, onLast, onPrev, onShowQueue, volume, loading=false, seek}) {

    const jumpToSource = () => {
        window.open(theMusic.jumpUrl, '_blank');
    }

    return <ConfigProvider
        theme={{
            components: {
                Button: {
                    onlyIconSizeSM: 28,
                    onlyIconSize: 32,
                    onlyIconSizeLG: 36,
                }
            },
        }}
    >
        <Row justify="space-evenly" align="middle" style={{height: "40vh"}}>
            <Avatar size={200} src={theMusic?.icon} onClick={jumpToSource}/>
        </Row>
        <Row>
            <Typography.Title level={5} ellipsis>{theMusic?.name}</Typography.Title>
        </Row>
        {If (isNotNull(seek)).then(() => <>
            <Row>
                <Slider {...seek} style={{width: "100%"}}/>
            </Row>
            <Row justify="space-between" align="middle">
                <Typography.Text>{seek.formatter(seek.value)}</Typography.Text>
                <Typography.Text>{seek.formatter(seek.max)}</Typography.Text>
            </Row>
        </>).endIf()}
        <Row justify="space-evenly" align="middle">
            <Button type="text" icon={<StepBackwardOutlined />} size="small" disabled={isNull(onPrev)} onClick={onPrev} />
            {If (loading).then(() => (
                <Button type="text" icon={<LoadingOutlined />} size="large" disabled/>
            )).elseIf(isPlaying).then(() => (
                <Button type="text" icon={<PauseOutlined />} size="large" onClick={onStop}/>
            )).else(() => (
                <Button type="text" icon={<CaretRightOutlined />} size="large" onClick={onStart.bind(this, null)}/>
            ))}
            <Button type="text" icon={<StepForwardOutlined />} onClick={onLast} />
            {If (isNotNull(volume)).then(() => (
                <Popover content={<Spin spinning={false}>
                    <Popover content="为这首歌单独配置音量">
                        <Switch size="small" value={volume.useCustomVolume} onChange={volume.onCustomChange}/>
                    </Popover>
                    {If (volume.useCustomVolume).then(() =>
                        <UpdateSlider vertical {...volume} style={{height: 100}} tooltip={{open: false}}/>
                    ).else(() =>
                        <UpdateSlider vertical {...volume} style={{height: 100}} tooltip={{open: false}}/>
                    )}
                </Spin>} trigger="hover">
                    <Button type="text" icon={<SoundOutlined />}/>
                </Popover>
            )).endIf()}
            <Button type="text" icon={<BarsOutlined />} onClick={onShowQueue} />
        </Row>
    </ConfigProvider>
}