import DefaultTable from "../../../../components/default-table";
import {listRandomTalk} from "../../../../api";
import {useMemo} from "react";
import AddRandomTalk from "./AddRandomTalk";

const columnsConfig = [
    {title: 'id', key: 'id', width: 60, type: 'sorter'},
    {title: '发送渠道', key: 'senderName', width: 100, type: 'search'},
    {title: '渠道类型', key: 'sendType', width: 100, type: 'choose', chooseMap: 'sendTypeList'},
    {title: '分组', key: 'function', width: 100},
    {title: '提问', key: 'req', width: 200},
    {title: '回答', key: 'resp', width: 600},
];

const otherResources = {
}

const buttons = [AddRandomTalk];
export default function RandomTalk(props) {

    return <DefaultTable
        isShowRowSelection={false}
        globalProps={props}
        buttons={buttons}
        columnsConfig={columnsConfig}
        getDataApi={listRandomTalk}
        needResourcesList={['sendTypeList', 'botSenderList']}
        otherResources={otherResources}
        defaultSorter={useMemo(() => ({field: 'id', order: 'descend'}), [])}
    />
}