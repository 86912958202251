import DefaultTable from "../../../../components/default-table";
import {listBotUser} from "../../../../api";
import {useMemo} from "react";
import BotChoose from "./BotChoose";
import EditBotUser from "./EditUserStatus";
import BindUser from "./BindUser";

const buttons = [BotChoose, EditBotUser, BindUser]
const needResourcesList=['sendTypeResource', 'robotTypeList', 'userTypeList']
const columnsConfig = [
    {title: 'id', key: 'id', width: 60, type: 'search'},
    {title: '用户名', key: 'name', width: 150, type: 'search'},
    {title: '头像', key: 'face', width: 60, type: 'image'},
    {title: '状态', key: 'status', width: 70, type: 'choose', chooseMap: 'userStatusList'},
    {title: '来源', key: 'type', width: 70, type: 'choose', chooseMap: 'userTypeList'},
    {title: 'QQId', key: 'qq', width: 95},
    {title: 'QQ频道Id', key: 'tinyId', width: 153},
    {title: 'KookId', key: 'kookUserId', width: 95},
    {title: 'mcId', key: 'minecraftUserId', width: 270},
    {title: 'QQ频道官方Id', key: 'qqGuildUserId', width: 168},
    {title: '英文名', key: 'enName', width: 120},
    {title: '积分', key: 'score', width: 70}
];

const otherResources = {
    userStatusList: [{text: '正常', value: 0, tag: 'green'},{text: '停用', value: -1, tag: 'red'}],
}
export default function BotUserManager(props) {
    const defaultFilters = useMemo(() => ({ status: [0] }), []);
    return <DefaultTable
        isMultipleSelect={true}
        globalProps={props}
        needResourcesList={needResourcesList}
        otherResources={otherResources}
        buttons={buttons}
        columnsConfig={columnsConfig}
        getDataApi={listBotUser}
        defaultFilters={defaultFilters}
        defaultPagination={{pageSize: 10, current: 1}}
    />
}